import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { auth_end_points } from "../api/api_end_points";
import axiosInstance from "../api/AxiosInstance";

export const SignInRequest = createAsyncThunk(
  "/signin",
  async (user, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(auth_end_points.signin, user);

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const LogOutRequest = createAsyncThunk(
  "/LogOutRequest",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`${auth_end_points.logOut}/${data}`);

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

const initialState = {
  loginstatus: "idle",
  isLoggedin: false,
  userDetails: null,
  logOutStatus: "idle",
  logOut_succes: false,
};

const LoginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    checkLoggedIn: (state) => {
      const user =
        localStorage.getItem("userDetails") !== undefined
          ? localStorage.getItem("userDetails")
          : null;
      state.userDetails = JSON.parse(user);
      if (localStorage.getItem("token")) {
        state.isLoggedin = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SignInRequest.pending, (state) => {
        state.loginstatus = "loading";

        state.userDetails = null;
      })

      .addCase(SignInRequest.fulfilled, (state, { payload }) => {
        state.loginstatus = "idle";
        state.message = payload?.message;

        if (payload?.status === 200) {
          state.isLoggedin = true;
          toast.success(state.message, { type: "success" });
          localStorage.setItem("token", payload?.token);
          const user = {
            full_name: payload?.data?.full_name,
            email: payload?.data?.email,
            _id: payload?.data?._id,
          };
          state.userDetails=user;
          localStorage.setItem("userDetails", JSON.stringify(user));
        } else {
          state.isLoggedin = false;
          toast.error(state.message, { type: "error" });
        }
      })
      .addCase(SignInRequest.rejected, (state, action) => {
        state.loginstatus = "idle";
        state.isLoggedin = false;
        state.message = "Something went wrong";
      })
      //log out
      .addCase(LogOutRequest.pending, (state) => {
        state.logOutStatus = "loading";
        state.userDetails = null;
      })

      .addCase(LogOutRequest.fulfilled, (state, { payload }) => {
        state.logOutStatus = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.isLoggedin = false;
          toast.success(state.message, { type: "success" });
          localStorage.clear();
          window.location.pathname = "/login";
        } else {
          state.isLoggedin = true;
          toast.error(state.message, { type: "error" });
        }
      })
      .addCase(LogOutRequest.rejected, (state, action) => {
        state.logOutStatus = "idle";
        state.isLoggedin = false;
        state.message = "Something went wrong";
      });
  },
});

export const { checkLoggedIn } = LoginSlice.actions;

export default LoginSlice.reducer;

