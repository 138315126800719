import React from "react";
import InfoIcon from "@mui/icons-material/Info";
const NoContentCard = ({ img, msg }) => {
  return (
    <div
      style={{ borderRadius: "15px" }}
      className="d-flex flex-column p-5 bg-white cardBody  justify-content-center align-items-center"
    >
      <InfoIcon className="text-muted" />
      <h5 className="text-center mt-2 f-14 text-muted">{msg}</h5>
    </div>
  );
};
export default NoContentCard;
