export const baseURL="https://clockwork-admin.dedicateddevelopers.us/api"
export const photoURL=""

export const auth_end_points={
    signin:'/user/signin',
    logOut:'/user/logout'
}

export const Accelerate_Schedule_end_points={
    add_schedule:'/accelerateschedule/add',
    scheduleList:"/accelerateschedule/list",
    edit_schedule:"/accelerateschedule/edit",
    remove_schedule:"/accelerateschedule/remove",
    schedule_details:'/accelerateschedule/details',
    list:'/container/list',
    AddContainerList:'/container/addcontainer',
    updateConatiner:'/container/editcontainer',
    deleteContainer:'/container/remove',
    terminalList:'/terminal-recipients/list'
}

export const end_points={
    pilelocation_list:"/pilelocation/list"
}

export const terminal_end_points={
    add_terminal:"/appointment/add",
    terminalList:"/appointment/list",
    edit_terminal:"/appointment/update",
    remove_terminal:"",
    terminal_details:'',
}