import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import InfoIcon from "@mui/icons-material/Info";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";

import {
  Checkbox,
  Chip,
  FormControlLabel,
  ListItemText,
  OutlinedInput,
  Select as MuiSelect,
  Switch,
  Tab,
  Tabs,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import "./Home.scss";
//  import Header from "./Components/Sharemodule/Header/Header";
import Header from "../../Components/Sharemodule/Header/Header";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import {
  addNewAcceralateSchedule,
  deleteAcceralateSchedule,
  editAcceralateSchedule,
  getPileLocations,
  getScheduleList,
  getTerminalList,
} from "../../Redux/Home.slice";

import Dropzone from "react-dropzone";
import moment from "moment";
import { Popover } from "@mui/material";
import { validPhone } from "../../Utils/Utils";
import TableSkeleton from "../Skeleton/TableSkeleton";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import Select from "react-select";
// import {Select} from '@mui/material'
import { toast } from "react-toastify";
import NoContentCard from "../NoContent/NoContentCard";
const momentTimezone = require("moment-timezone");

const ariaLabel = { "aria-label": "description" };
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 502,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "10px",
  p: 2,
  px: 2,
  pb: 3,
};

const table_style = {
  fontWeight: "bold",
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#4caf50" : "#308fe8",
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Home() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  // <Box
  //   component="form"
  //   sx={{
  //     "& > :not(style)": { m: 1 },
  //   }}
  //   noValidate
  //   autoComplete="off"
  // >
  //   <Input defaultValue="Hello world" inputProps={ariaLabel} />
  //   <Input placeholder="Placeholder" inputProps={ariaLabel} />
  //   <Input disabled defaultValue="Disabled" inputProps={ariaLabel} />
  //   <Input defaultValue="Error" error inputProps={ariaLabel} />
  // </Box>;
  // const LightTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.white,
  //     color: "rgba(0, 0, 0, 0.87)",
  //     boxShadow: theme.shadows[1],
  //     fontSize: 25,
  //   },
  // }));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handle_Close = () => {
    setAnchorEl(null);
  };

  const open_status = Boolean(anchorEl);
  const id = open_status ? "simple-popover" : undefined;

  const {
    getScheduleList_status,
    getScheduleList_data,
    NewSchedule_created,
    editAcceralateSchedule_edited,
  } = useSelector((s) => s.home);

  const [filter, setfilter] = useState("");
  const [filter_code, set_filter_code] = useState(false);
  const [filter_dates, set_filter_dates] = useState(false);
  const [filter_status, set_filter_status] = useState(false);
  const [filter_voyage, set_filter_voyage] = useState(false);
  const [filter_container, set_filter_container] = useState(false);
  const [filter_conatiner_remaining, set_filter_conatiner_remaining] =
    useState(false);
  const [filter_progress, set_filter_progress] = useState(false);
  const [data, setdata] = useState(null);

  const handleFilter = (name) => {
    setfilter(name);
    // let sort_order;
    let sort_order;
    if (name === "code") {
      set_filter_code(!filter_code);
      sort_order = filter_code ? "asc" : "desc";
      dispatch(getScheduleList({ sort_order, sort_field: "code" }));
      return;
    }

    if (name === "Program Dates") {
      set_filter_dates(!filter_dates);
      sort_order = filter_dates ? "asc" : "desc";
      dispatch(
        getScheduleList({ sort_order, sort_field: "program_start_date" })
      );
      return;
    }
    if (name === "Status") {
      set_filter_status(!filter_status);
      sort_order = filter_status ? "asc" : "desc";
      dispatch(getScheduleList({ sort_order, sort_field: "status" }));
      return;
    }
    if (name === "Voyage") {
      set_filter_voyage(!filter_voyage);
      sort_order = filter_voyage ? "asc" : "desc";
      dispatch(getScheduleList({ sort_order, sort_field: "voyage" }));
      return;
    }
    if (name === "Containers") {
      set_filter_container(!filter_container);
      sort_order = filter_container ? "asc" : "desc";
      dispatch(getScheduleList({ sort_order, sort_field: "containercount" }));
      return;
    }
    if (name === "Containers Remaining") {
      set_filter_conatiner_remaining(!filter_conatiner_remaining);
      sort_order = filter_conatiner_remaining ? "asc" : "desc";
      dispatch(getScheduleList({ sort_order, sort_field: "container_used" }));
      return;
    }
    if (name === "Progress") {
      set_filter_progress(!filter_progress);
      sort_order = filter_progress ? "asc" : "desc";
      // setdata(filter_by_progress(getScheduleList_data, sort_order));

      dispatch(getScheduleList({ sort_order, sort_field: "progress" }));
      return;
    }

    if (name === "Completed") {
      // set_filter_progress(!filter_progress);
      // sort_order = filter_progress ? "asc" : "desc";
      handle_Close();
      dispatch(getScheduleList({ status: "Completed" }));
      return;
    }
    if (name === "Scheduled") {
      // set_filter_progress(!filter_progress);
      // sort_order = filter_progress ? "asc" : "desc";
      handle_Close();
      dispatch(getScheduleList({ status: "Scheduled" }));
      return;
    }
    if (name === "in_progress") {
      // set_filter_progress(!filter_progress);
      // sort_order = filter_progress ? "asc" : "desc";
      handle_Close();
      dispatch(getScheduleList({ status: "In Progress" }));
      return;
    }
    if (name === "Overdue") {
      // set_filter_progress(!filter_progress);
      // sort_order = filter_progress ? "asc" : "desc";
      handle_Close();
      dispatch(getScheduleList({ status: "Overdue" }));
      return;
    }
  };

  useEffect(() => {
    dispatch(getScheduleList());
    dispatch(getTerminalList());
  }, []);

  useEffect(() => {
    getScheduleList_data !== null && setdata(getScheduleList_data);
  }, [getScheduleList_data]);

  useEffect(() => {
    if (NewSchedule_created || editAcceralateSchedule_edited) {
      dispatch(getScheduleList());
    }
  }, [NewSchedule_created, editAcceralateSchedule_edited]);

  return (
    <>
      <Header />

      <div className="main-container">
        <div className="brade-camp">
          <Link to="#"></Link> <span> Accelerate Hub </span>
        </div>

        <div className="dashboard-contain">
          <div className="dashboard-top">
            <h1>Accelerate Hub</h1>
            <Button
              variant="text"
              className="schedule-button"
              onClick={handleOpen}
            >
              SCHEDULE
            </Button>
            <ScheduleModal open={open} handleClose={handleClose} Item={Item} />
          </div>
          <div className="dashboard-main">
            <TableContainer
              className="main-table"
              component={Paper}
              style={{ maxHeight: "90vh" }}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 950 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="cursor"
                      onClick={() => handleFilter("code")}
                    >
                      Code
                      {filter === "code" && (
                        <span>
                          {filter_code ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </span>
                      )}
                    </TableCell>
                    <TableCell
                      className="c-head cursor"
                      onClick={() => handleFilter("Program Dates")}
                    >
                      Program Dates
                      {filter === "Program Dates" && (
                        <span>
                          {filter_dates ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </span>
                      )}
                    </TableCell>
                    <TableCell
                      className="c-head cursor"
                      aria-describedby={id}
                      onClick={handleClick}
                      align="center"
                    >
                      Status
                      <span>
                        <KeyboardArrowDownIcon />
                      </span>
                    </TableCell>
                    <Popover
                      id={id}
                      open={open_status}
                      anchorEl={anchorEl}
                      onClose={handle_Close}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem onClick={(e) => handleFilter("Completed")}>
                        Completed
                      </MenuItem>
                      <MenuItem onClick={(e) => handleFilter("Scheduled")}>
                        Scheduled
                      </MenuItem>
                      <MenuItem onClick={(e) => handleFilter("in_progress")}>
                        In Progress
                      </MenuItem>
                      <MenuItem onClick={(e) => handleFilter("Overdue")}>
                        Overdue
                      </MenuItem>
                    </Popover>
                    <TableCell
                      className="c-head cursor"
                      onClick={() => handleFilter("Voyage")}
                      align="left"
                    >
                      Voyage
                      {filter === "Voyage" && (
                        <span>
                          {filter_voyage ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </span>
                      )}
                    </TableCell>
                    <TableCell
                      className="c-head cursor"
                      onClick={() => handleFilter("Containers")}
                      align="center"
                    >
                      Containers
                      {filter === "Containers" && (
                        <span>
                          {filter_container ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </span>
                      )}
                    </TableCell>
                    <TableCell
                      className="c-head cursor"
                      onClick={() => handleFilter("Containers Remaining")}
                      align="center"
                    >
                      Containers Remaining{" "}
                      {filter === "Containers Remaining" && (
                        <span>
                          {filter_conatiner_remaining ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </span>
                      )}
                    </TableCell>
                    <TableCell
                      className="c-head cursor"
                      onClick={() => handleFilter("Progress")}
                      align="left"
                    >
                      Progress
                      {filter === "Progress" && (
                        <span>
                          {filter_progress ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </span>
                      )}
                    </TableCell>
                    <TableCell
                      className="c-head cursor"
                      align="left"
                    ></TableCell>
                    <TableCell className="c-head cursor" align="center">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getScheduleList_status === "loading" ||
                  getScheduleList_data === null ? (
                    <>
                      {Array.from({ length: 10 }, (_, i) => (
                        <TableSkeleton key={i} count={8} />
                      ))}
                      {/* {Array(10).map((item, index) => {
                        return <TableSkeleton key={index} />;
                      })} */}
                    </>
                  ) : (
                    <>
                      {data?.length > 0 ? (
                        <>
                          {data?.map((row) => {
                            return <TableRows key={row?._id} row={row} />;
                          })}
                        </>
                      ) : null}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {data?.length === 0 && <NoContentCard msg="No data found" />}
          </div>
        </div>
      </div>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ marginTop: 20 }}>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ScheduleModal = ({ open, handleClose, Item }) => {
  const [Shifts, setShifts] = useState(1);
  const [code, setcode] = useState("");
  const [note, setnote] = useState("");
  const [voyage, setvoyage] = useState("");
  const [shift_1_terminal_contact_name, setshift_1_terminal_contact_name] =
    useState("");
  const [shift_1_terminal_contact_number, setshift_1_terminal_contact_number] =
    useState("");
  const [shift_2_terminal_contact_name, setshift_2_terminal_contact_name] =
    useState("");
  const [shift_2_terminal_contact_number, setshift_2_terminal_contact_number] =
    useState("");
  const [csv, setcsv] = useState(null);
  const [kickOfUpdate, setkickOfUpdate] = useState(false);
  const [kickoffTerminalUsers, setkickoffTerminalUsers] = useState([]);
  const [kickoffMotorCarrierReceipents, setkickoffMotorCarrierReceipents] =
    useState([]);
  const [perodicUpdate, setperodicUpdate] = useState(false);
  const [periodicTerminalUsers, setperiodicTerminalUsers] = useState([]);
  const [periodicMotorCarrierReceipents, setperiodicMotorCarrierReceipents] =
    useState([]);

  const [scac, setscac] = useState("");
  const [containerNumber, setcontainerNumber] = useState("");
  const [startShift, setstartShift] = useState("first");
  const [Conventional, setConventional] = useState("Conventional");
  const [incluseSaturdayShift, setincluseSaturdayShift] = useState(false);
  const [vesselEtaDate, setvesselEtaDate] = useState(new Date());
  const [vesselName, setvesselName] = useState("");
  const [err_code, setcode_err] = useState("");
  const [err_pileLocation, setpileLocation_err] = useState("");
  const [err_voyage, setvoyage_err] = useState("");
  const [err_csv, setcsv_err] = useState(null);
  const [err_containerNumber, setcontainerNumber_err] = useState("");
  const [
    err_shift_1_terminal_contact_name,
    setshift_1_terminal_contact_name_err,
  ] = useState("");
  const [
    err_shift_1_terminal_contact_number,
    setshift_1_terminal_contact_number_err,
  ] = useState("");
  const [
    err_shift_2_terminal_contact_name,
    setshift_2_terminal_contact_name_err,
  ] = useState("");
  const [
    err_shift_2_terminal_contact_number,
    setshift_2_terminal_contact_number_err,
  ] = useState("");

  const {
    getPileLocations_data,
    addNewScheduleStatus,
    NewSchedule_created,
    getTerminalList_data,
  } = useSelector((s) => s.home);

  const dispatch = useDispatch();
  const date = new Date();
  const [pile_options, setPile_options] = useState([]);
  const [selected_pile_options, set_selected_Pile_options] = useState([]);

  const [tabvalue, settabValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    settabValue(newValue);
  };

  const [program_start_date, set_program_start_date] = useState(date);
  const [program_end_date, set_program_end_date] = useState(
    moment(date).add(1, "days").toISOString()
  );

  const handleChange = (selectedOptions) => {
    set_selected_Pile_options(selectedOptions);
  };

  const handleChange_kickoffTerminalUsers = (event) => {
    const {
      target: { value },
    } = event;
    setkickoffTerminalUsers(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleDelete_kickoffTerminalUser = (index) => {
    //delete from array by index
    const newArray = [...kickoffTerminalUsers];
    newArray.splice(index, 1);
    setkickoffTerminalUsers(newArray);
  };

  const handleDelete_periodicTerminalUsers = (index) => {
    //delete from array by index
    const newArray = [...periodicTerminalUsers];
    newArray.splice(index, 1);
    setperiodicTerminalUsers(newArray);
  };

  const handle_periodicTerminalUsers = (event) => {
    const {
      target: { value },
    } = event;
    setperiodicTerminalUsers(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [key, setkey] = useState("");
  const handleContainerNumber = (e) => {
    e.preventDefault();
    setcsv(null);
    setcontainerNumber(e.target.value);
  };

  const handleConventional = (e) => {
    e.preventDefault();
    setConventional(e.target.value);
    if (e.target.value === "TIS") {
      set_selected_Pile_options([]);
    }
  };

  const handleKeyPress = (e) => {
    setkey(e.key);
  };

  const handleFileInput = (acceptedFiles) => {
    if (
      acceptedFiles[0]?.type === ".csv" ||
      acceptedFiles[0]?.type === "text/csv" ||
      acceptedFiles[0]?.type === "application/vnd.ms-excel" ||
      acceptedFiles[0]?.type === "text/x-csv" ||
      acceptedFiles[0]?.type === "application/x-csv" ||
      acceptedFiles[0]?.type === "text/comma-separated-values" ||
      acceptedFiles[0]?.type === "text/x-comma-separated-values" ||
      acceptedFiles[0]?.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setcsv(acceptedFiles[0]);
      setcontainerNumber("");
      setcsv_err(null);
    } else {
      setcsv(null);
      setcsv_err("Add only .csv ,.xlxs file");
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setcode_err(null);
    setpileLocation_err(null);
    setcsv_err(null);
    setvoyage_err(null);
    setshift_1_terminal_contact_name_err(null);
    setshift_1_terminal_contact_number_err(null);
    setshift_2_terminal_contact_name_err(null);
    setshift_2_terminal_contact_number_err(null);

    if (
      code === "" &&
      csv === null &&
      containerNumber === "" &&
      selected_pile_options?.length <= 0 &&
      voyage === "" &&
      shift_1_terminal_contact_name === "" &&
      shift_1_terminal_contact_number === "" &&
      shift_2_terminal_contact_name === "" &&
      shift_2_terminal_contact_number === ""
    ) {
      setcode_err("Enter code");
      setpileLocation_err("Enter pile location");
      setcsv_err("Enter csv");
      setvoyage_err("Enter voyage");
      setshift_1_terminal_contact_name_err("Enter contact name");
      setshift_1_terminal_contact_number_err("Enter contact number");
      setshift_2_terminal_contact_name_err("Enter contact name");
      setshift_2_terminal_contact_number_err("Enter contact number");
      return;
    }

    if (code === "") {
      setcode_err("Enter code");
      return;
    }

    if (selected_pile_options?.length <= 0 && Conventional !== "TIS") {
      setpileLocation_err("Enter pile location");
      return;
    }
    if (voyage === "") {
      setvoyage_err("Enter voyage");

      return;
    }

    if (shift_1_terminal_contact_name === "") {
      setshift_1_terminal_contact_name_err("Enter contact name");
      return;
    }

    if (shift_1_terminal_contact_number === "") {
      setshift_1_terminal_contact_number_err("Enter contact number");
      return;
    }

    if (!validPhone(shift_1_terminal_contact_number)) {
      setshift_1_terminal_contact_number_err("Phone number is not valid");
      return;
    }

    if (shift_2_terminal_contact_name === "") {
      setshift_2_terminal_contact_name_err("Enter contact name");
      return;
    }

    if (shift_2_terminal_contact_number === "") {
      setshift_2_terminal_contact_number_err("Enter contact number");
      return;
    }

    if (!validPhone(shift_2_terminal_contact_number)) {
      setshift_2_terminal_contact_number_err("Phone number is not valid");
      return;
    }

    if (csv === null && containerNumber === "") {
      setcsv_err("Enter csv or conatiner number");
      return;
    }

    if (containerNumber !== "" || containerNumber !== null) {
      if (e.target.value?.split(/\r\n|\r|\n/)?.length > 100) {
        setcontainerNumber_err("Cannot add more than 100");
        return;
      }
    }

    const form = new FormData();
    form.append("code", code);
    form.append("status", "scheduled");
    form.append("notes", note);
    form.append("voyage", voyage);
    form.append("vesselName", vesselName);
    form.append("scac", scac);
    form.append("shift_1_terminal_contact_name", shift_1_terminal_contact_name);
    form.append(
      "shift_1_terminal_contact_number",
      shift_1_terminal_contact_number
    );
    form.append("shift_2_terminal_contact_name", shift_2_terminal_contact_name);
    form.append(
      "shift_2_terminal_contact_number",
      shift_2_terminal_contact_number
    );

    // let _start_date = moment(valueDate[0]).toISOString(true);
    // let _end_date = moment(valueDate[1]).toISOString(true);
    // let _start_date = moment(program_start_date).toISOString(true);
    // let _end_date = moment(program_end_date).toISOString(true);
    // form.append("program_start_date", _start_date);
    // form.append("program_end_date", _end_date);
    form.append(
      "program_start_date",
      moment.tz(moment(program_start_date).format('YYYY-MM-DD'), 'America/Los_Angeles').format('YYYY-MM-DD')
    );
    form.append(
      "program_end_date",
    
      moment.tz(moment(program_end_date).format('YYYY-MM-DD'), 'America/Los_Angeles').format('YYYY-MM-DD')
  
    );


    form.append("vessel_eta_date", moment(vesselEtaDate).toISOString(true));

    csv !== null && form.append("csv", csv);
    containerNumber !== "" && form.append("containerNumber", containerNumber);

    form.append("no_of_shifts", Shifts);
    let temp = [];
    selected_pile_options.map((item) => {
      temp.push(item?.value);
    });
    form.append("pile_locationId", JSON.stringify(temp));
    form.append("enableKickOffUpdate", kickOfUpdate);
    form.append("enablePeriodicUpdate", perodicUpdate);
    let temp_kickoffTerminalUsers = [];
    if (kickoffTerminalUsers?.length > 0) {
      kickoffTerminalUsers.map((item) => {
        let x = JSON.parse(item);
        temp_kickoffTerminalUsers.push(x._id);
      });
    }

    let temp_periodicTerminalUsers = [];
    if (periodicTerminalUsers?.length > 0) {
      periodicTerminalUsers.map((item) => {
        let x = JSON.parse(item);
        temp_periodicTerminalUsers.push(x._id);
      });
    }

    form.append(
      "kickoffTerminalUsers",
      JSON.stringify(temp_kickoffTerminalUsers)
    );
    form.append(
      "periodicTerminalUsers",
      JSON.stringify(temp_periodicTerminalUsers)
    );
    form.append(
      "kickoffMotorCarrierReceipents",
      JSON.stringify(kickoffMotorCarrierReceipents)
    );
    form.append(
      "periodicMotorCarrierReceipents",
      JSON.stringify(periodicMotorCarrierReceipents)
    );

    form.append("starting_shift", startShift);
    form.append("conventional_or_tis", Conventional);
    form.append("saturday_shift", incluseSaturdayShift);

    dispatch(addNewAcceralateSchedule(form));
  };

  useEffect(() => {
    dispatch(getPileLocations());
  }, []);

  useEffect(() => {
    if (getPileLocations_data !== null) {
      let temp = [];
      getPileLocations_data?.map((item) => {
        temp.push({
          value: item?._id,
          label: item?.pile_location,
        });
      });

      setPile_options(temp);
    }
  }, [getPileLocations_data]);

  useEffect(() => {
    if (getTerminalList_data !== null) {
      let temp = [];
      getTerminalList_data?.map((item) => {
        if (
          item?._id === "6204c918c5eab8079858b46b" ||
          item?._id === "6204c88ec5eab8079858b468" ||
          item?._id === "6204c864c5eab8079858b465"
        ) {
          temp.push(JSON.stringify(item));
        }
      });

      setperiodicTerminalUsers(temp);

      setkickoffTerminalUsers(temp);

      // setPile_options(temp);
    }
  }, [getTerminalList_data, open]);

  useEffect(() => {
    if (NewSchedule_created) {
      handleClose();
      setShifts(1);
      setcode("");
      setvoyage("");
      setcsv(null);
      setcontainerNumber("");
      dispatch(getPileLocations());
      dispatch(getScheduleList());
    }
  }, [NewSchedule_created]);

  return (
    <>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box className="modal-main modal-main1" sx={style}>
          <form onSubmit={handleSubmit} autoComplete="true">
            <h2 id="unstyled-modal-title">
              New Accelerate Program{" "}
              <button className="cross-button" onClick={handleClose}>
                <ClearOutlinedIcon />
              </button>
            </h2>

            <Grid container spacing={2}>
              <Box sx={{ padding: "10px 20px", width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tabvalue}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                    aria-label="basic tabs example"
                    centered
                  >
                    <Tab label="General" {...a11yProps(0)} />
                    <Tab label="Alerts" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={tabvalue} index={0}>
                  <Grid container spacing={2}>
                    <Grid
                      className="group-main t-space-one"
                      item
                      xs={12}
                      lg={12}
                    >
                      <Item>
                        <Input
                          type="text"
                          value={code}
                          onChange={(e) => setcode(e.target.value)}
                          placeholder="Group Code"
                          inputProps={ariaLabel}
                        />
                      </Item>
                      <span className="error-t errText ">{err_code}</span>
                    </Grid>

                    <Grid item lg={6} xs={6} className="vessel-voyage-date">
                      <Item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Program start date"
                            value={program_start_date}
                            onChange={(newValue) => {
                              //program_start_date must be before program_end_date
                              if (
                                moment(newValue).isBefore(
                                  moment(program_end_date)
                                )
                              ) {
                                set_program_start_date(newValue);
                              } else {
                                toast.error(
                                  "Program start date must be before program end date"
                                );
                              }
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Item>
                    </Grid>

                    <Grid item lg={6} xs={6} className="vessel-voyage-date">
                      <Item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Program end date"
                            value={program_end_date}
                            onChange={(newValue) => {
                              // program_end_date must be after program_start_date
                              if (
                                newValue.getTime() >
                                program_start_date.getTime()
                              ) {
                                set_program_end_date(newValue);
                              } else {
                                toast(
                                  "Program end date must be after program start date",
                                  {
                                    type: "error",
                                  }
                                );
                              }
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Item>
                    </Grid>

                    <Grid item lg={6} xs={6} className="vessel-voyage">
                      <Item>
                        <Input
                          placeholder="Vessel/Voyage"
                          value={voyage}
                          onChange={(e) => setvoyage(e.target.value)}
                          inputProps={ariaLabel}
                        />
                      </Item>
                      <span className="error-t errText">{err_voyage}</span>
                    </Grid>

                    <Grid item lg={6} xs={6} className="vessel-voyage-date">
                      <Item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Vessel ETA"
                            value={vesselEtaDate}
                            onChange={(newValue) => {
                              setvesselEtaDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Item>
                    </Grid>

                    <Grid item lg={12} xs={12} className="vessel-voyage">
                      <Item>
                        <Input
                          placeholder="Vessel Name"
                          value={vesselName}
                          onChange={(e) => setvesselName(e.target.value)}
                          inputProps={ariaLabel}
                        />
                      </Item>
                      {/* <span className="error-t errText">{err_voyage}</span> */}
                    </Grid>

                    <Grid item lg={12} xs={12} className="shifts">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Starting Shift
                          </InputLabel>
                          <MuiSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={startShift}
                            label="Shifts"
                            onChange={(e) => setstartShift(e.target.value)}
                          >
                            <MenuItem value="first">First</MenuItem>
                            <MenuItem value="second">Second</MenuItem>
                          </MuiSelect>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item lg={12} xs={12} className="shifts">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Conventional / TIS
                          </InputLabel>
                          <MuiSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={Conventional}
                            label="Shifts"
                            onChange={handleConventional}
                          >
                            <MenuItem value="Conventional">
                              Conventional
                            </MenuItem>
                            <MenuItem value="TIS">TIS</MenuItem>
                          </MuiSelect>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      className=""
                      style={{
                        display:
                          Conventional === "Conventional" ? "block" : "none",
                      }}
                      lg={12}
                      xs={12}
                    >
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          {/* <InputLabel id="demo-simple-select-label">
                      Pile Location
                    </InputLabel> */}
                          {/* <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pileLocation}
                      label="p"
                      multiple
                      onChange={handleChangePileLocation}
                    >
                      {getPileLocations_data !== null &&
                        getPileLocations_data?.length > 0 &&
                        getPileLocations_data?.map((item) => {
                          return (
                            <MenuItem key={item?._id} value={item?._id}>
                              {item?.pile_location}
                            </MenuItem>
                          );
                        })}
                    </Select> */}

                          <Select
                            textFieldProps={{
                              label: "Pile Location",
                              InputLabelProps: {
                                shrink: true,
                              },
                            }}
                            placeholder={<div>Pile Location</div>}
                            //  onChange={(e) => setavail_time(e.target.value)}
                            closeMenuOnSelect={false}
                            value={selected_pile_options} // set selected values
                            onChange={handleChange}
                            isMulti
                            options={pile_options}
                            menuPortalTarget={document.body}
                            styles={{
                              menu: (state) => ({
                                backgroundColor: "#fefefe",
                              }),
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (base) => ({
                                ...base,
                                padding: "10px 0px !important",
                              }),
                            }}
                          />
                        </FormControl>
                      </Box>
                      <span className="error-t errText">
                        {err_pileLocation}
                      </span>
                    </Grid>

                    <Grid item lg={12} xs={12} className="shifts">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            "# of Shifts
                          </InputLabel>
                          <MuiSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={Shifts}
                            label="Shifts"
                            onChange={(e) => setShifts(e.target.value)}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                          </MuiSelect>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid
                      className="group-main t-space-one mt-3"
                      item
                      xs={12}
                      lg={12}
                    >
                      <Item>
                        <Input
                          type="text"
                          value={scac}
                          onChange={(e) => setscac(e.target.value)}
                          placeholder="SCAC"
                          inputProps={ariaLabel}
                        />
                      </Item>
                    </Grid>

                    <Grid className="shift-number-b" item xs={12}>
                      <Item>Shift One terminal contact</Item>
                    </Grid>
                    <Grid item lg={6} xs={6} className="shift-one-b">
                      <Item>
                        <Box
                          sx={{
                            display: "flex",
                            "& > :not(style)": { m: 1 },
                          }}
                        >
                          <TextField
                            helperText=""
                            id="demo-helper-text-aligned"
                            label="Name"
                            value={shift_1_terminal_contact_name}
                            onChange={(e) =>
                              setshift_1_terminal_contact_name(e.target.value)
                            }
                          />
                        </Box>
                        <span className="error-t errText">
                          {err_shift_1_terminal_contact_name}
                        </span>
                      </Item>
                    </Grid>

                    <Grid item lg={6} xs={6} className="shift-one-b">
                      <Item>
                        <Box
                          sx={{
                            display: "flex",
                            "& > :not(style)": { m: 1 },
                          }}
                        >
                          <TextField
                            helperText=" "
                            id="demo-helper-text-aligned-no-helper"
                            label="Phone Number"
                            value={shift_1_terminal_contact_number}
                            onChange={(e) =>
                              setshift_1_terminal_contact_number(e.target.value)
                            }
                          />
                        </Box>
                        <span className="error-t errText">
                          {err_shift_1_terminal_contact_number}
                        </span>
                      </Item>
                    </Grid>
                    <Grid className="shift-number-b" item xs={12}>
                      <Item>Shift two terminal contact</Item>
                    </Grid>
                    <Grid item lg={6} xs={6} className="shift-one-b">
                      <Item>
                        <Box
                          sx={{
                            display: "flex",
                            "& > :not(style)": { m: 1 },
                          }}
                        >
                          <TextField
                            helperText=""
                            id="demo-helper-text-aligned"
                            label="Name"
                            value={shift_2_terminal_contact_name}
                            onChange={(e) =>
                              setshift_2_terminal_contact_name(e.target.value)
                            }
                          />
                        </Box>
                        <span className="error-t errText">
                          {err_shift_2_terminal_contact_name}
                        </span>
                      </Item>
                    </Grid>
                    <Grid item lg={6} xs={6} className="shift-one-b">
                      <Item>
                        <Box>
                          <TextField
                            helperText=" "
                            id="demo-helper-text-aligned-no-helper"
                            label="Phone Number"
                            value={shift_2_terminal_contact_number}
                            onChange={(e) =>
                              setshift_2_terminal_contact_number(e.target.value)
                            }
                          />
                          <span className="error-t errText">
                            {err_shift_2_terminal_contact_number}
                          </span>
                        </Box>
                      </Item>
                    </Grid>

                    <Grid className="con-number mt-3" item xs={12}>
                      <Item>
                        <TextareaAutosize
                          value={note}
                          onChange={(e) => setnote(e.target.value)}
                          aria-label="empty textarea"
                          placeholder="Notes"
                        />
                      </Item>
                      {/* <span className="error-t errText">{err_containerNumber}</span> */}
                    </Grid>

                    <Grid className="last" item xs={12}>
                      <Item>
                        <Dropzone
                          acceptedFiles={[
                            ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          ]}
                          onDrop={handleFileInput}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <label
                              className="d-flex flex-column"
                              htmlFor="icon-button-file"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <IconButton
                                aria-label="upload picture"
                                component="span"
                              >
                                <FileUploadIcon />
                                <span>Upload csv (.csv, .xlx)</span>
                              </IconButton>
                            </label>
                          )}
                        </Dropzone>
                      </Item>
                      <p style={{ display: "flex", flexDirection: "column" }}>
                        {err_csv !== null && (
                          <span className="error-t errText">{err_csv}</span>
                        )}
                        <a
                          href="/assets/images/test.xlsx"
                          download="sampleCsv"
                          className="linkText"
                        >
                          Download sample csv
                        </a>
                        <span>{csv?.name}</span>
                      </p>
                    </Grid>

                    <div style={{ textAlign: "center", width: "100%" }}>Or</div>
                    <Grid className="con-number" item xs={12}>
                      {/* <span className="e-text">
                  Enter up to 25 tracking numbers one per line
                </span> */}
                      <Item>
                        <TextareaAutosize
                          value={containerNumber}
                          onChange={handleContainerNumber}
                          onKeyDown={handleKeyPress}
                          aria-label="empty textarea"
                          maxRows={2}
                          placeholder="Enter your containers numbers split by new line"
                        />
                      </Item>
                      <span className="error-t errText">
                        {err_containerNumber}
                      </span>
                    </Grid>
                    <Grid item lg={12} xs={12} className="shifts">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={incluseSaturdayShift}
                              checked={incluseSaturdayShift}
                              onChange={(e) =>
                                setincluseSaturdayShift(e.target.checked)
                              }
                            />
                          }
                          label="Include saturday shift"
                        />
                      </Box>
                    </Grid>
                  </Grid>{" "}
                </TabPanel>
                <TabPanel value={tabvalue} index={1}>
                  <Grid container spacing={2}>
                    <Grid className="" item xs={12} lg={12}>
                      <div className="d-flex justify-content-between">
                        <div className="kick-off">
                          <Switch
                            value={kickOfUpdate}
                            checked={kickOfUpdate}
                            onChange={(e) => setkickOfUpdate(!kickOfUpdate)}
                          />
                          <span className="enable-kick">
                            {kickOfUpdate ? "Disable" : "Enable"} Kick-Off
                            Update
                          </span>
                        </div>
                        <div>
                          <Tooltip
                            className="dd"
                            // style={{ margin: "-20px" }}
                            style={{ margin: "-20px" }}
                            arrow
                            //title=" Alerts 10 minutes prior to Kick-Off Date"
                            title={
                              <h1
                                style={{
                                  //color: "#fff",
                                  // fontSize: "10px",
                                  width: "105px",
                                  marginBottom: "0px",
                                }}
                              >
                                Alerts 10 minutes prior to Kick-Off Date
                              </h1>
                            }
                            placement="left-start"
                          >
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </Grid>

                    <Grid item className="terminal-main" lg={12} xs={12}>
                      <Box>
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Terminal Recipients
                          </InputLabel>
                          <MuiSelect
                            label="Terminal Recipients"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={kickoffTerminalUsers}
                            onChange={handleChange_kickoffTerminalUsers}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value, index) => (
                                  <Chip
                                    deleteIcon={
                                      <CancelIcon
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                    label={JSON.parse(value)?.email}
                                    className="chef-valu"
                                    onDelete={() => {
                                      handleDelete_kickoffTerminalUser(index);
                                    }}
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {getTerminalList_data?.map((name) => (
                              <MenuItem
                                key={name?._id}
                                value={JSON.stringify(name)}
                              >
                                {/* <Checkbox
                                  checked={personName.indexOf(name) > -1}
                                /> */}
                                <ListItemText primary={name?.email} />
                              </MenuItem>
                            ))}
                          </MuiSelect>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid
                      className="t-space-one m-c-recipents mt-1"
                      item
                      xs={12}
                      lg={12}
                    >
                      <Box className="">
                        {/* <InputLabel id="demo-multiple-checkbox-label">
                          Motor Carrier Recipients
                        </InputLabel> */}
                        <ReactTagInput
                          tags={kickoffMotorCarrierReceipents}
                          placeholder="Motor Carrier Recipients"
                          editable={true}
                          readOnly={false}
                          removeOnBackspace={true}
                          onChange={(newTags) =>
                            setkickoffMotorCarrierReceipents(newTags)
                          }
                          validator={(value) => {
                            // Don't actually validate e-mails this way
                            const isEmail = value.indexOf("@") !== -1;
                            if (!isEmail) {
                              toast("Please enter valid email address", {
                                type: "error",
                              });
                            }
                            // Return boolean to indicate validity
                            return isEmail;
                          }}
                        />
                      </Box>
                    </Grid>

                    <hr />

                    <Grid className="" item xs={12} lg={12}>
                      <Box>
                        <div className="d-flex justify-content-between">
                          <div>
                            <Switch
                              value={perodicUpdate}
                              checked={perodicUpdate}
                              onChange={(e) => setperodicUpdate(!perodicUpdate)}
                            />
                            <span className="periodic">
                              {" "}
                              {perodicUpdate ? "Disable" : "Enable"} Periodic
                              Update
                            </span>
                          </div>
                          <div>
                            <Tooltip
                              className="dd"
                              style={{ margin: "-20px" }}
                              arrow
                              //title=" Alerts 10 minutes prior to Kick-Off Date"
                              title={
                                <h1
                                  className="text-capitalize"
                                  style={{
                                    // color: "#fff",
                                    // fontSize: "10px",
                                    width: "105px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Alerts every 2 hours after Kick-Off Date
                                </h1>
                              }
                              placement="left-start"
                            >
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </Box>
                    </Grid>

                    <Grid item className="terminal-main" lg={12} xs={12}>
                      <Box>
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Terminal Recipients
                          </InputLabel>
                          <MuiSelect
                            label="Terminal Recipients"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={periodicTerminalUsers}
                            onChange={handle_periodicTerminalUsers}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value, index) => (
                                  <Chip
                                    label={JSON.parse(value)?.email}
                                    className="chef-valu"
                                    deleteIcon={
                                      <CancelIcon
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                    onDelete={() => {
                                      handleDelete_periodicTerminalUsers(index);
                                    }}
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {getTerminalList_data?.map((name) => (
                              <MenuItem
                                key={name?._id}
                                value={JSON.stringify(name)}
                              >
                                {/* <Checkbox
                                  checked={personName.indexOf(name) > -1}
                                /> */}
                                <ListItemText primary={name?.email} />
                              </MenuItem>
                            ))}
                          </MuiSelect>
                        </FormControl>
                      </Box>
                    </Grid>

                    {/* <Grid item className="" lg={12} xs={12}>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <Select
                            textFieldProps={{
                              label: "Terminal Recipients",
                              InputLabelProps: {
                                shrink: true,
                              },
                            }}
                            placeholder={<div>Terminal Recipients</div>}
                            //  onChange={(e) => setavail_time(e.target.value)}
                            closeMenuOnSelect={false}
                            value={selected_pile_options} // set selected values
                            onChange={handleChange}
                            isMulti
                            options={pile_options}
                            menuPortalTarget={document.body}
                            styles={{
                              menu: (state) => ({
                                backgroundColor: "#fefefe",
                              }),
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (base) => ({
                                ...base,
                                padding: "10px 0px !important",
                              }),
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid> */}

                    <Grid
                      className=" t-space-one mt-1 m-c-recipents"
                      item
                      xs={12}
                      lg={12}
                    >
                      <ReactTagInput
                        tags={periodicMotorCarrierReceipents}
                        placeholder="Motor Carrier Recipients"
                        editable={true}
                        readOnly={false}
                        removeOnBackspace={true}
                        onChange={(newTags) =>
                          setperiodicMotorCarrierReceipents(newTags)
                        }
                        validator={(value) => {
                          // Don't actually validate e-mails this way
                          const isEmail = value.indexOf("@") !== -1;
                          if (!isEmail) {
                            toast("Please enter valid email address", {
                              type: "error",
                            });
                          }
                          // Return boolean to indicate validity
                          return isEmail;
                        }}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </Box>

              <Grid className="last-modal" item xs={12}>
                <Item>
                  <Button
                    disabled={addNewScheduleStatus === "loading"}
                    type="submit"
                    variant="text"
                    className="schedule-b"
                  >
                    {addNewScheduleStatus === "loading" && (
                      <CircularProgress color="inherit" size={15} />
                    )}
                    SCHEDULE
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </form>
        </Box>
      </StyledModal>
    </>
  );
};

const TableRows = ({ row }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const [edit, setEdit] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handle_Close = () => {
    setAnchorEl(null);
  };

  const pop_open = Boolean(anchorEl);
  const id = pop_open ? "simple-popover" : undefined;

  const openEditModal = () => {
    handle_Close();
    setEdit(true);
  };
  const closeEditModal = () => setEdit(false);

  const dispatch = useDispatch();

  const handleDelete = () => {
    handle_Close();
    dispatch(deleteAcceralateSchedule(row?._id));
  };

  const GotoDashboard = () => {
    history.push(`/Apmtdashboard/${row?._id}`);
    // return <Redirect to={`/Apmtdashboard/${row?._id}`} />;
  };

  return (
    <>
      <TableRow
        key={row?._id}
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
        className="cursor"
      >
        <TableCell
          onClick={GotoDashboard}
          style={table_style}
          component="td"
          scope="row"
        >
          {row?.code}
        </TableCell>

        <TableCell
          // style={{ Color: "green" }}
          align="left"
          onClick={GotoDashboard}
        >
          {momentTimezone(row?.program_start_date)
            .tz("America/Los_Angeles")
            .format("MMMM DD")}
          -
          {momentTimezone(row?.program_end_date)
            .tz("America/Los_Angeles")
            .format("MMMM DD")}
        </TableCell>

        <TableCell align="center" onClick={GotoDashboard}>
          {row?.status === "Completed" ? (
            <Button className="completed-button">{row?.status}</Button>
          ) : row?.status === "In Progress" ? (
            <Button className="inprogress-button">{row?.status}</Button>
          ) : row?.status === "Scheduled" ? (
            <Button className="scheduled-button  ">{row?.status}</Button>
          ) : (
            <Button className="scheduled-button active-buttonRed ">
              <span className="redText">{row?.status}</span>
            </Button>
          )}
        </TableCell>
        <TableCell align="left" onClick={GotoDashboard}>
          {row?.voyage}
        </TableCell>
        <TableCell align="center" onClick={GotoDashboard}>
          {row?.containercount}
        </TableCell>
        <TableCell align="center" onClick={GotoDashboard}>
          {row?.container_remaining}
        </TableCell>
        <TableCell align="left">
          <Box sx={{ flexGrow: 1 }} onClick={GotoDashboard}>
            <BorderLinearProgress variant="determinate" value={row?.progress} />
          </Box>
        </TableCell>
        <TableCell onClick={GotoDashboard} align="left">
          {row?.progress}%
        </TableCell>

        <TableCell align="center">
          <Button
            className="button-last"
            variant="text"
            aria-describedby={id}
            onClick={handleClick}
          >
            <h5>...</h5>
          </Button>
          <Popover
            id={id}
            open={pop_open}
            anchorEl={anchorEl}
            onClose={handle_Close}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <ul className="list-unstyled p-2">
              <li>
                {" "}
                <button onClick={openEditModal} className="btn shadow-none">
                  <span>Edit</span>
                </button>
              </li>
              <li>
                {" "}
                <button onClick={handleDelete} className="btn shadow-none">
                  <span>Delete</span>
                </button>
              </li>
            </ul>
          </Popover>
        </TableCell>
      </TableRow>

      {edit && (
        <EditModal Data={row} open={edit} handleClose={closeEditModal} />
      )}
    </>
  );
};

const EditModal = ({ open, handleClose, Data }) => {
  const [startShift, setstartShift] = useState(Data?.starting_shift);
  const [Conventional, setConventional] = useState(Data?.conventional_or_tis);
  const [incluseSaturdayShift, setincluseSaturdayShift] = useState(
    Data?.saturday_shift
  );
  const [vesselName, setvesselName] = useState(Data?.vesselName);

  const [vesselEtaDate, setvesselEtaDate] = useState(Data?.vessel_eta_date);


  const [Shifts, setShifts] = useState(Data?.no_of_shifts);
  const [code, setcode] = useState(Data?.code);
  const [program_start_date, setprogram_start_date] = useState(
    momentTimezone(Data?.program_start_date)
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD")
  );
  const [note, setnote] = useState(Data?.notes);
  const [program_end_date, setprogram_end_date] = useState(
    momentTimezone(Data?.program_end_date)
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD")
  );
  const [shift_1_terminal_contact_name, setshift_1_terminal_contact_name] =
    useState(Data?.shift_1_terminal_contact_name);
  const [shift_1_terminal_contact_number, setshift_1_terminal_contact_number] =
    useState(Data?.shift_1_terminal_contact_number);
  const [shift_2_terminal_contact_name, setshift_2_terminal_contact_name] =
    useState(Data?.shift_2_terminal_contact_name);
  const [shift_2_terminal_contact_number, setshift_2_terminal_contact_number] =
    useState(Data?.shift_2_terminal_contact_number);
  const [scac, setscac] = useState(Data?.scac);
  const [pile_options, setPile_options] = useState([]);
  const [selected_pile_options, set_selected_Pile_options] = useState([]);

  const [voyage, setvoyage] = useState(Data?.voyage);
  const [csv, setcsv] = useState(null);
  const [containerNumber, setcontainerNumber] = useState(
    Data?.containerNumber !== undefined ? Data?.containerNumber : ""
  );

  const [kickOfUpdate, setkickOfUpdate] = useState(Data?.enableKickOffUpdate);
  const [kickoffTerminalUsers, setkickoffTerminalUsers] = useState([]);
  const [kickoffMotorCarrierReceipents, setkickoffMotorCarrierReceipents] =
    useState(Data?.kickoffMotorCarrierReceipents);
  const [perodicUpdate, setperodicUpdate] = useState(
    Data?.enablePeriodicUpdate
  );
  const [periodicTerminalUsers, setperiodicTerminalUsers] = useState([]);
  const [periodicMotorCarrierReceipents, setperiodicMotorCarrierReceipents] =
    useState(Data?.periodicMotorCarrierReceipents);

  const [
    err_shift_1_terminal_contact_name,
    setshift_1_terminal_contact_name_err,
  ] = useState("");
  const [
    err_shift_1_terminal_contact_number,
    setshift_1_terminal_contact_number_err,
  ] = useState("");

  const [
    err_shift_2_terminal_contact_name,
    setshift_2_terminal_contact_name_err,
  ] = useState("");
  const [
    err_shift_2_terminal_contact_number,
    setshift_2_terminal_contact_number_err,
  ] = useState("");
  const [err_code, setcode_err] = useState("");
  const [err_pileLocation, setpileLocation_err] = useState("");
  // const [err_program_start_date, setprogram_start_date_err] = useState("");
  // const [err_program_end_date, setprogram_end_date_err] = useState("");
  const [err_voyage, setvoyage_err] = useState("");
  const [err_csv, setcsv_err] = useState(null);
  const [err_containerNumber, setcontainerNumber_err] = useState("");
  const [err_no_of_shifts, setno_of_shifts_err] = useState("");

  const {
    getPileLocations_data,
    editAcceralateSchedule_status,
    editAcceralateSchedule_edited,
    getTerminalList_data,
  } = useSelector((s) => s.home);

  const dispatch = useDispatch();

  const handleChange = (selectedOptions) => {
    set_selected_Pile_options(selectedOptions);
  };
  const handleFileInput = (acceptedFiles) => {
    if (
      acceptedFiles[0]?.type === ".csv" ||
      acceptedFiles[0]?.type === "text/csv" ||
      acceptedFiles[0]?.type === "application/vnd.ms-excel" ||
      acceptedFiles[0]?.type === "text/x-csv" ||
      acceptedFiles[0]?.type === "application/x-csv" ||
      acceptedFiles[0]?.type === "text/comma-separated-values" ||
      acceptedFiles[0]?.type === "text/x-comma-separated-values" ||
      acceptedFiles[0]?.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setcsv(acceptedFiles[0]);
      setcontainerNumber("");
      setcsv_err(null);
    } else {
      setcsv(null);
      setcsv_err("Add only .csv ,.xlxs file");
      return;
    }
  };

  const [key, setkey] = useState("");
  const handleContainerNumber = (e) => {
    e.preventDefault();
    setcontainerNumber(e.target.value);
    setcsv(null);
  };

  const handleKeyPress = (e) => {
    setkey(e.key);
  };
  const handleConventional = (e) => {
    e.preventDefault();
    setConventional(e.target.value);
    if (e.target.value === "TIS") {
      set_selected_Pile_options([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setcode_err(null);
    setpileLocation_err(null);
    setcsv_err(null);
    setvoyage_err(null);
    setshift_1_terminal_contact_name_err(null);
    setshift_1_terminal_contact_number_err(null);
    setshift_2_terminal_contact_name_err(null);
    setshift_2_terminal_contact_number_err(null);

    if (
      code === "" &&
      csv === null &&
      containerNumber === "" &&
      selected_pile_options?.length <= 0 &&
      voyage === "" &&
      err_shift_1_terminal_contact_name === "" &&
      err_shift_1_terminal_contact_number === "" &&
      err_shift_2_terminal_contact_name === "" &&
      err_shift_2_terminal_contact_number === ""
    ) {
      setcode_err("Enter code");
      setpileLocation_err("Enter pile location");
      setcsv_err("Enter csv");
      setvoyage_err("Enter voyage");
      setshift_1_terminal_contact_name_err("Enter contact name");
      setshift_1_terminal_contact_number_err("Enter contact number");
      setshift_2_terminal_contact_name_err("Enter contact name");
      setshift_2_terminal_contact_number_err("Enter contact number");
      return;
    }

    if (code === "") {
      setcode_err("Enter code");
      return;
    }

    if (selected_pile_options?.length <= 0 && Conventional !== "TIS") {
      setpileLocation_err("Enter pile location");
      return;
    }
    if (voyage === "") {
      setvoyage_err("Enter voyage");

      return;
    }

    if (shift_1_terminal_contact_name === "") {
      setshift_1_terminal_contact_name_err("Enter contact name");
      return;
    }

    if (shift_1_terminal_contact_number === "") {
      setshift_1_terminal_contact_number_err("Enter contact number");
      return;
    }

    if (!validPhone(shift_1_terminal_contact_number)) {
      setshift_1_terminal_contact_number_err("Phone number is not valid");
      return;
    }

    if (shift_2_terminal_contact_name === "") {
      setshift_2_terminal_contact_name_err("Enter contact name");
      return;
    }

    if (shift_2_terminal_contact_number === "") {
      setshift_2_terminal_contact_number_err("Enter contact number");
      return;
    }

    if (!validPhone(shift_2_terminal_contact_number)) {
      setshift_2_terminal_contact_number_err("Phone number is not valid");
      return;
    }

    // if (csv === null && containerNumber === "") {
    //   setcsv_err("Enter csv");
    //   return;
    // }

    const form = new FormData();
    form.append("vesselName", vesselName);
    form.append("accelerateschedule_id", Data?._id);
    form.append("code", code);
    form.append("voyage", voyage);
    form.append("notes", note);
    form.append("vessel_eta_date", moment(vesselEtaDate).toISOString(true));
    form.append("shift_1_terminal_contact_name", shift_1_terminal_contact_name);
    form.append(
      "shift_1_terminal_contact_number",
      shift_1_terminal_contact_number
    );
    form.append("shift_2_terminal_contact_name", shift_2_terminal_contact_name);
    form.append(
      "shift_2_terminal_contact_number",
      shift_2_terminal_contact_number
    );
    // let _start_date = moment(valueDate[0]).toISOString(true);
    // let _end_date = moment(valueDate[1]).toISOString(true);
    // let f = _start_date.split("T")[0];
    // let f2 = _end_date.split("T")[0];

    // form.append("program_start_date", `${f}T07:00:00.00+05:30`);
    // form.append("program_end_date", `${f2}T03:00:00.00+05:30`);
    // form.append(
    //   "program_start_date",
    //   moment(program_start_date).utc().toISOString()
    // );
    // form.append(
    //   "program_end_date",
    //   moment(program_end_date).utc().toISOString()
    // );

    form.append(
      "program_start_date",
      moment.tz(moment(program_start_date).format('YYYY-MM-DD'), 'America/Los_Angeles').format('YYYY-MM-DD')
    );
    form.append(
      "program_end_date",
    
      moment.tz(moment(program_end_date).format('YYYY-MM-DD'), 'America/Los_Angeles').format('YYYY-MM-DD')
  
    );

   


    


    csv !== null && form.append("csv", csv);
    containerNumber !== "" && form.append("containerNumber", containerNumber);

    form.append("no_of_shifts", Shifts);
    let temp = [];
    selected_pile_options.map((item) => {
      temp.push(item?.value);
    });
    form.append("pile_locationId", JSON.stringify(temp));
    form.append("scac", scac);
    form.append("enableKickOffUpdate", kickOfUpdate);
    form.append("enablePeriodicUpdate", perodicUpdate);
    let temp_kickoffTerminalUsers = [];
    if (kickoffTerminalUsers?.length > 0) {
      kickoffTerminalUsers.map((item) => {
        let x = JSON.parse(item);
        temp_kickoffTerminalUsers.push(x._id);
      });
    }

    let temp_periodicTerminalUsers = [];
    if (periodicTerminalUsers?.length > 0) {
      periodicTerminalUsers.map((item) => {
        let x = JSON.parse(item);
        temp_periodicTerminalUsers.push(x._id);
      });
    }

    form.append(
      "kickoffTerminalUsers",
      JSON.stringify(temp_kickoffTerminalUsers)
    );
    form.append(
      "periodicTerminalUsers",
      JSON.stringify(temp_periodicTerminalUsers)
    );
    form.append(
      "kickoffMotorCarrierReceipents",
      JSON.stringify(kickoffMotorCarrierReceipents)
    );
    form.append(
      "periodicMotorCarrierReceipents",
      JSON.stringify(periodicMotorCarrierReceipents)
    );
    form.append("starting_shift", startShift);
    form.append("conventional_or_tis", Conventional);
    form.append("saturday_shift", incluseSaturdayShift);

    dispatch(editAcceralateSchedule(form));
  };

  useEffect(() => {
    if (Data) {
      let temp = [];
      let temp2 = [];
      Data?.kickoffTerminalUsersDetails?.length > 0 &&
        Data?.kickoffTerminalUsersDetails?.map((item) => {
          temp.push(JSON.stringify(item));
        });

      Data?.periodicTerminalUsersDetails?.length > 0 &&
        Data?.periodicTerminalUsersDetails?.map((item) => {
          temp2.push(JSON.stringify(item));
        });

      setkickoffTerminalUsers(temp);
      setperiodicTerminalUsers(temp2);
    }
  }, []);

  useEffect(() => {
    editAcceralateSchedule_edited && handleClose();
  }, [editAcceralateSchedule_edited]);

  useEffect(() => {
    if (getPileLocations_data !== null) {
      let temp = [];
      getPileLocations_data?.map((item) => {
        temp.push({
          value: item?._id,
          label: item?.pile_location,
        });
      });

      setPile_options(temp);
    }
  }, [getPileLocations_data]);

  useEffect(() => {
    let temp = [];
    Data?.pilelocation_data?.map((item) => {
      temp.push({
        value: item?._id,
        label: item?.pile_location,
      });
    });
    set_selected_Pile_options(temp);
  }, []);

  const [tabvalue, settabValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    settabValue(newValue);
  };

  const handleChange_kickoffTerminalUsers = (event) => {
    const {
      target: { value },
    } = event;
    setkickoffTerminalUsers(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleDelete_kickoffTerminalUser = (index) => {
    //delete from array by index
    const newArray = [...kickoffTerminalUsers];
    newArray.splice(index, 1);
    setkickoffTerminalUsers(newArray);
  };

  const handleDelete_periodicTerminalUsers = (index) => {
    //delete from array by index
    const newArray = [...periodicTerminalUsers];
    newArray.splice(index, 1);
    setperiodicTerminalUsers(newArray);
  };

  const handle_periodicTerminalUsers = (event) => {
    const {
      target: { value },
    } = event;
    setperiodicTerminalUsers(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box className="modal-main modal-main1" sx={style}>
          <form onSubmit={handleSubmit}>
            <h2 id="unstyled-modal-title">
              Edit Accelerate Program{" "}
              <button className="cross-button" onClick={handleClose}>
                <ClearOutlinedIcon />
              </button>
            </h2>

            <Grid container spacing={2}>
              <Box sx={{ padding: "10px 20px", width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tabvalue}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                    aria-label="basic tabs example"
                    centered
                  >
                    <Tab label="General" {...a11yProps(0)} />
                    <Tab label="Alerts" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={tabvalue} index={0}>
                  <Grid container spacing={2}>
                    <Grid className="group-code-main" item xs={12} lg={12}>
                      <Item>
                        <Input
                          value={code}
                          onChange={(e) => setcode(e.target.value)}
                          placeholder="Group Code"
                          inputProps={ariaLabel}
                        />
                      </Item>
                      <span className="error-t errText">{err_code}</span>
                    </Grid>
                    <Grid className="p-date" item xs={6}>
                      <Item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Program start Date"
                            value={program_start_date}
                            onChange={(newValue) => {
                              //program_start_date must be before program_end_date
                              if (
                                moment(newValue).isBefore(
                                  moment(program_end_date)
                                )
                              ) {
                                setprogram_start_date(newValue);
                              } else {
                                toast.error(
                                  "Program start date must be before program end date"
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="" />
                            )}
                          />
                        </LocalizationProvider>
                      </Item>
                    </Grid>
                    <Grid className="p-date" item xs={6}>
                      <Item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Program End Date"
                            value={program_end_date}
                            onChange={(newValue) => {
                              // program_end_date must be after program_start_date
                              if (
                                moment(newValue).isAfter(
                                  moment(program_start_date)
                                )
                              ) {
                                setprogram_end_date(newValue);
                              } else {
                                toast.error(
                                  "Program end date must be after program start date"
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="" />
                            )}
                          />
                        </LocalizationProvider>
                      </Item>
                    </Grid>

                    <Grid item lg={6} xs={6} className="vessel-voyage">
                      <Item>
                        <Input
                          placeholder="Vessel/Voyage"
                          value={voyage}
                          onChange={(e) => setvoyage(e.target.value)}
                          inputProps={ariaLabel}
                        />
                      </Item>
                      <span className="error-t errText">{err_voyage}</span>
                    </Grid>
                    <Grid item lg={6} xs={6} className="vessel-voyage-date">
                      <Item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Vessel ETA"
                            value={vesselEtaDate}
                            onChange={(newValue) => {
                              setvesselEtaDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Item>
                    </Grid>

                    <Grid item lg={12} xs={12} className="vessel-voyage">
                      <Item>
                        <Input
                          placeholder="Vessel Name"
                          value={vesselName}
                          onChange={(e) => setvesselName(e.target.value)}
                          inputProps={ariaLabel}
                        />
                      </Item>
                      {/* <span className="error-t errText">{err_voyage}</span> */}
                    </Grid>

                    <Grid item lg={12} xs={12} className="shifts">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Starting Shift
                          </InputLabel>
                          <MuiSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={startShift}
                            label="Shifts"
                            onChange={(e) => setstartShift(e.target.value)}
                          >
                            <MenuItem value="first">First</MenuItem>
                            <MenuItem value="second">Second</MenuItem>
                          </MuiSelect>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item lg={12} xs={12} className="shifts">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Conventional / TIS
                          </InputLabel>
                          <MuiSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={Conventional}
                            label="Shifts"
                            onChange={handleConventional}
                          >
                            {" "}
                            <MenuItem value="Conventional">
                              Conventional
                            </MenuItem>
                            <MenuItem value="TIS">TIS</MenuItem>
                          </MuiSelect>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      xs={12}
                      style={{
                        display:
                          Conventional === "Conventional" ? "block" : "none",
                      }}
                    >
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          {/* <InputLabel id="demo-simple-select-label">
                      Pile Location
                    </InputLabel> */}
                          <Select
                            textFieldProps={{
                              label: "Pile Location",
                              InputLabelProps: {
                                shrink: true,
                              },
                            }}
                            placeholder={<div>Pile Location</div>}
                            //  onChange={(e) => setavail_time(e.target.value)}
                            closeMenuOnSelect={false}
                            value={selected_pile_options} // set selected values
                            onChange={handleChange}
                            isMulti
                            options={pile_options}
                            menuPortalTarget={document.body}
                            styles={{
                              menu: (state) => ({
                                backgroundColor: "#fefefe",
                              }),
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (base) => ({
                                ...base,
                                padding: "10px 0px !important",
                              }),
                            }}
                          />
                        </FormControl>
                      </Box>
                      <span className="error-t errText">
                        {err_pileLocation}
                      </span>
                    </Grid>

                    <Grid item lg={12} xs={12} className="shifts">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            "# of Shifts
                          </InputLabel>
                          <MuiSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={Shifts}
                            label="Shifts"
                            onChange={(e) => setShifts(e.target.value)}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                          </MuiSelect>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid
                      className="group-main t-space-one mt-3"
                      item
                      xs={12}
                      lg={12}
                    >
                      <Item>
                        <Input
                          type="text"
                          value={scac}
                          onChange={(e) => setscac(e.target.value)}
                          placeholder="SCAC"
                          inputProps={ariaLabel}
                        />
                      </Item>
                    </Grid>

                    <Grid className="shift-number-b" item xs={12}>
                      <Item>Shift One terminal contact</Item>
                    </Grid>
                    <Grid item lg={6} xs={6} className="shift-one-b">
                      <Item>
                        <Box
                          sx={{
                            display: "flex",
                            "& > :not(style)": { m: 1 },
                          }}
                        >
                          <TextField
                            helperText=""
                            id="demo-helper-text-aligned"
                            label="Name"
                            value={shift_1_terminal_contact_name}
                            onChange={(e) =>
                              setshift_1_terminal_contact_name(e.target.value)
                            }
                          />
                        </Box>
                        <span className="error-t errText">
                          {err_shift_1_terminal_contact_name}
                        </span>
                      </Item>
                    </Grid>

                    <Grid item lg={6} xs={6} className="shift-one-b">
                      <Item>
                        <Box
                          sx={{
                            display: "flex",
                            "& > :not(style)": { m: 1 },
                          }}
                        >
                          <TextField
                            helperText=" "
                            id="demo-helper-text-aligned-no-helper"
                            label="Phone Number"
                            value={shift_1_terminal_contact_number}
                            onChange={(e) =>
                              setshift_1_terminal_contact_number(e.target.value)
                            }
                          />
                        </Box>
                        <span className="error-t errText">
                          {err_shift_1_terminal_contact_number}
                        </span>
                      </Item>
                    </Grid>
                    <Grid className="shift-number-b" item xs={12}>
                      <Item>Shift two terminal contact</Item>
                    </Grid>
                    <Grid item lg={6} xs={6} className="shift-one-b">
                      <Item>
                        <Box
                          sx={{
                            display: "flex",
                            "& > :not(style)": { m: 1 },
                          }}
                        >
                          <TextField
                            helperText=""
                            id="demo-helper-text-aligned"
                            label="Name"
                            value={shift_2_terminal_contact_name}
                            onChange={(e) =>
                              setshift_2_terminal_contact_name(e.target.value)
                            }
                          />
                        </Box>
                        <span className="error-t errText">
                          {err_shift_2_terminal_contact_name}
                        </span>
                      </Item>
                    </Grid>
                    <Grid item lg={6} xs={6} className="shift-one-b">
                      <Item>
                        <Box>
                          <TextField
                            helperText=" "
                            id="demo-helper-text-aligned-no-helper"
                            label="Phone Number"
                            value={shift_2_terminal_contact_number}
                            onChange={(e) =>
                              setshift_2_terminal_contact_number(e.target.value)
                            }
                          />
                          <span className="error-t errText">
                            {err_shift_2_terminal_contact_number}
                          </span>
                        </Box>
                      </Item>
                    </Grid>

                    <Grid className="con-number mt-3" item xs={12}>
                      <Item>
                        <TextareaAutosize
                          value={note}
                          onChange={(e) => setnote(e.target.value)}
                          aria-label="empty textarea"
                          placeholder="Notes"
                        />
                      </Item>
                      {/* <span className="error-t errText">{err_containerNumber}</span> */}
                    </Grid>

                    <Grid className="last" item xs={12}>
                      <Item>
                        <Dropzone
                          acceptedFiles={[
                            ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          ]}
                          onDrop={handleFileInput}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <label
                              htmlFor="icon-button-file"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <IconButton
                                aria-label="upload picture"
                                component="span"
                              >
                                <FileUploadIcon />
                                <span>Upload (.csv, .xlx)</span>
                              </IconButton>
                            </label>
                          )}
                        </Dropzone>
                      </Item>
                      <p style={{ display: "flex", flexDirection: "column" }}>
                        {err_csv !== null && (
                          <span className="error-t errText">{err_csv}</span>
                        )}
                        <a
                          href="/assets/images/test.xlsx"
                          download="sampleCsv"
                          className="linkText"
                        >
                          Download sample csv
                        </a>
                        <span align="center">{csv?.name}</span>
                      </p>
                    </Grid>

                    <div style={{ textAlign: "center", width: "100%" }}>Or</div>
                    <Grid className="con-number" item xs={12}>
                      {/* <span className="e-text">
                  Enter up to 25 tracking numbers one per line
                </span> */}
                      <Item>
                        <TextareaAutosize
                          value={containerNumber}
                          onChange={handleContainerNumber}
                          onKeyDown={handleKeyPress}
                          aria-label="empty textarea"
                          placeholder="Enter Your Containers Numbers"
                        />
                      </Item>
                      <span className="error-t errText">
                        {err_containerNumber}
                      </span>
                    </Grid>

                    <Grid item lg={12} xs={12} className="shifts">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={incluseSaturdayShift}
                              checked={incluseSaturdayShift}
                              onChange={(e) =>
                                setincluseSaturdayShift(e.target.checked)
                              }
                            />
                          }
                          label="Include saturday shift"
                        />
                      </Box>
                    </Grid>
                  </Grid>{" "}
                </TabPanel>

                <TabPanel value={tabvalue} index={1}>
                  <Grid container spacing={2}>
                    <Grid className="" item xs={12} lg={12}>
                      <div className="d-flex justify-content-between">
                        <div className="kick-off">
                          <Switch
                            value={kickOfUpdate}
                            checked={kickOfUpdate}
                            onChange={(e) => setkickOfUpdate(!kickOfUpdate)}
                          />
                          <span className="enable-kick">
                            {kickOfUpdate ? "Disable" : "Enable"} Kick-Off
                            Update
                          </span>
                        </div>
                        <div>
                          <Tooltip
                            arrow
                            title=" Alerts 10 minutes prior to Kick-Off Date"
                            placement="left-start"
                          >
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </Grid>
                    {/* <Grid item className="terminal-main2" lg={12} xs={12}>
                      <Box>
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Terminal Recipients
                          </InputLabel>
                          <MuiSelect
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={personName}
                            onChange={handleChange3}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={personName.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </MuiSelect>
                        </FormControl>
                      </Box>
                    </Grid> */}
                    <Grid item className="terminal-main" lg={12} xs={12}>
                      <Box>
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Terminal Recipients
                          </InputLabel>
                          <MuiSelect
                            label="Terminal Recipients"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={kickoffTerminalUsers}
                            onChange={handleChange_kickoffTerminalUsers}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value, index) => (
                                  <Chip
                                    deleteIcon={
                                      <CancelIcon
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                    label={JSON.parse(value)?.email}
                                    className="chef-valu"
                                    onDelete={() => {
                                      handleDelete_kickoffTerminalUser(index);
                                    }}
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {getTerminalList_data?.map((name) => (
                              <MenuItem
                                key={name?._id}
                                value={JSON.stringify(name)}
                              >
                                {/* <Checkbox
                                  checked={personName.indexOf(name) > -1}
                                /> */}
                                <ListItemText primary={name?.email} />
                              </MenuItem>
                            ))}
                          </MuiSelect>
                        </FormControl>
                      </Box>
                    </Grid>
                    {/* <Grid item className="motor-career" lg={12} xs={12}>
                      <Box>
                        <FormControl fullWidth>
                          <Select
                            textFieldProps={{
                              label: "Terminal Recipients",
                              InputLabelProps: {
                                shrink: true,
                              },
                            }}
                            placeholder={<div>Terminal Recipients</div>}
                            //  onChange={(e) => setavail_time(e.target.value)}
                            closeMenuOnSelect={false}
                            value={selected_pile_options} // set selected values
                            onChange={handleChange}
                            isMulti
                            options={pile_options}
                            menuPortalTarget={document.body}
                            styles={{
                              menu: (state) => ({
                                backgroundColor: "#fefefe",
                              }),
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (base) => ({
                                ...base,
                                padding: "10px 0px !important",
                              }),
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid> */}

                    <Grid className=" t-space-one mt-1" item xs={12} lg={12}>
                      <Box>
                        {/* <InputLabel id="demo-multiple-checkbox-label">
                          Motor Carrier Recipients
                        </InputLabel> */}
                        <ReactTagInput
                          tags={kickoffMotorCarrierReceipents}
                          placeholder="Motor Carrier Recipients"
                          editable={true}
                          readOnly={false}
                          removeOnBackspace={true}
                          onChange={(newTags) =>
                            setkickoffMotorCarrierReceipents(newTags)
                          }
                          validator={(value) => {
                            // Don't actually validate e-mails this way
                            const isEmail = value.indexOf("@") !== -1;
                            if (!isEmail) {
                              toast("Please enter valid email address", {
                                type: "error",
                              });
                            }
                            // Return boolean to indicate validity
                            return isEmail;
                          }}
                        />
                      </Box>
                    </Grid>

                    <hr />

                    <Grid className="" item xs={12} lg={12}>
                      <Box>
                        <div className="d-flex justify-content-between">
                          <div>
                            <Switch
                              value={perodicUpdate}
                              checked={perodicUpdate}
                              onChange={(e) => setperodicUpdate(!perodicUpdate)}
                            />
                            <span className="periodic">
                              {" "}
                              {perodicUpdate ? "Disable" : "Enable"} Periodic
                              Update
                            </span>
                          </div>
                          <div>
                            <Tooltip
                              arrow
                              title=" Alerts 10 minutes prior to Kick-Off Date"
                              placement="left-start"
                            >
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </Box>
                    </Grid>

                    <Grid item className="terminal-main" lg={12} xs={12}>
                      <Box>
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Terminal Recipients
                          </InputLabel>
                          <MuiSelect
                            label="Terminal Recipients"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={periodicTerminalUsers}
                            onChange={handle_periodicTerminalUsers}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value, index) => (
                                  <Chip
                                    label={JSON.parse(value)?.email}
                                    className="chef-valu"
                                    deleteIcon={
                                      <CancelIcon
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                    onDelete={() => {
                                      handleDelete_periodicTerminalUsers(index);
                                    }}
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {getTerminalList_data?.map((name) => (
                              <MenuItem
                                key={name?._id}
                                value={JSON.stringify(name)}
                              >
                                {/* <Checkbox
                                  checked={personName.indexOf(name) > -1}
                                /> */}
                                <ListItemText primary={name?.email} />
                              </MenuItem>
                            ))}
                          </MuiSelect>
                        </FormControl>
                      </Box>
                    </Grid>

                    {/* <Grid item className="" lg={12} xs={12}>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <Select
                            textFieldProps={{
                              label: "Terminal Recipients",
                              InputLabelProps: {
                                shrink: true,
                              },
                            }}
                            placeholder={<div>Terminal Recipients</div>}
                            //  onChange={(e) => setavail_time(e.target.value)}
                            closeMenuOnSelect={false}
                            value={selected_pile_options} // set selected values
                            onChange={handleChange}
                            isMulti
                            options={pile_options}
                            menuPortalTarget={document.body}
                            styles={{
                              menu: (state) => ({
                                backgroundColor: "#fefefe",
                              }),
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (base) => ({
                                ...base,
                                padding: "10px 0px !important",
                              }),
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid> */}

                    <Grid className=" t-space-one mt-1" item xs={12} lg={12}>
                      <ReactTagInput
                        tags={periodicMotorCarrierReceipents}
                        placeholder="Motor Carrier Recipients"
                        editable={true}
                        readOnly={false}
                        removeOnBackspace={true}
                        onChange={(newTags) =>
                          setperiodicMotorCarrierReceipents(newTags)
                        }
                        validator={(value) => {
                          // Don't actually validate e-mails this way
                          const isEmail = value.indexOf("@") !== -1;
                          if (!isEmail) {
                            toast("Please enter valid email address", {
                              type: "error",
                            });
                          }
                          // Return boolean to indicate validity
                          return isEmail;
                        }}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </Box>
            </Grid>

            <Grid container spacing={2}>
              <Grid className="last-modal" item xs={12}>
                <Item>
                  <Button
                    disabled={editAcceralateSchedule_status === "loading"}
                    type="submit"
                    variant="text"
                    className="schedule-b"
                  >
                    {editAcceralateSchedule_status === "loading" && (
                      <CircularProgress
                        color="inherit"
                        className="mx-2"
                        size={15}
                      />
                    )}
                    EDIT SCHEDULE
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </form>
        </Box>
      </StyledModal>
    </>
  );
};

export default Home;
