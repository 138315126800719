import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import produce from "immer";
import { toast } from "react-toastify";
import {
  Accelerate_Schedule_end_points,
  end_points,
} from "../api/api_end_points";
import axiosInstance from "../api/AxiosInstance";

export const addNewAcceralateSchedule = createAsyncThunk(
  "/add new sechdule",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        Accelerate_Schedule_end_points.add_schedule,
        data,
        {}
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const editAcceralateSchedule = createAsyncThunk(
  "/editAcceralateSchedule",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        Accelerate_Schedule_end_points.edit_schedule,
        data
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const deleteAcceralateSchedule = createAsyncThunk(
  "/deleteAcceralateSchedule",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${Accelerate_Schedule_end_points.remove_schedule}/${data}`
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const getPileLocations = createAsyncThunk(
  "/getPileLocations",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(end_points.pilelocation_list);

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const getScheduleList = createAsyncThunk(
  "/getScheduleList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        Accelerate_Schedule_end_points.scheduleList,
        data
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const getTerminalList = createAsyncThunk(
  "/getTerminalList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        Accelerate_Schedule_end_points.terminalList,
        data
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

const initialState = {
  addNewScheduleStatus: "idle",
  NewSchedule_created: false,
  getPileLocations_status: "idle",
  getPileLocations_data: null,
  getScheduleList_status: "idle",
  getScheduleList_data: null,
  editAcceralateSchedule_status: "idle",
  editAcceralateSchedule_data: null,
  editAcceralateSchedule_edited: false,
  deleteAcceralateSchedule_status: "idle",
  getTerminalList_status: "idle",
  getTerminalList_data: null,
};

const HomeSlice = createSlice({
  name: "home slice",
  initialState,
  extraReducers: (builders) => {
    builders
      .addCase(addNewAcceralateSchedule.pending, (state) => {
        state.addNewScheduleStatus = "loading";
        state.NewSchedule_created = false;
      })
      .addCase(addNewAcceralateSchedule.fulfilled, (state, { payload }) => {
        state.addNewScheduleStatus = "idle";
        // state.NewSchedule_created = true;
        if (payload?.status === 200) {
          state.NewSchedule_created = true;

          toast(payload?.message, { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(addNewAcceralateSchedule.rejected, (state, action) => {
        state.addNewScheduleStatus = "idle";
      })

      .addCase(getPileLocations.pending, (state) => {
        state.getPileLocations_status = "loading";
        state.getPileLocations_data = null;
      })

      .addCase(getPileLocations.fulfilled, (state, { payload }) => {
        state.getPileLocations_status = "idle";
        if (payload?.status === 200) {
          state.getPileLocations_data = payload?.data;
        } else {
          state.getPileLocations_data = [];
        }
      })
      .addCase(getPileLocations.rejected, (state, action) => {
        state.getPileLocations_status = "idle";
      })

      .addCase(getScheduleList.pending, (state) => {
        state.getScheduleList_status = "loading";
        state.getPileLocations_data = null;
        state.editAcceralateSchedule_edited = false;
      })

      .addCase(getScheduleList.fulfilled, (state, { payload }) => {
        state.getScheduleList_status = "idle";
        if (payload?.status === 200) {
          state.getScheduleList_data = payload?.data;
        } else {
          state.getScheduleList_data = [];
        }
      })
      .addCase(getScheduleList.rejected, (state, action) => {
        state.getScheduleList_status = "idle";
      })
      //terminal list data getTerminalList
      .addCase(getTerminalList.pending, (state) => {
        state.getTerminalList_status = "loading";
      })

      .addCase(getTerminalList.fulfilled, (state, { payload }) => {
        state.getTerminalList_status = "idle";
        if (payload?.status === 200) {
          state.getTerminalList_data = payload?.data;
        } else {
          state.getTerminalList_data = [];
        }
      })
      .addCase(getTerminalList.rejected, (state, action) => {
        state.getTerminalList_status = "idle";
      })
      //edit
      .addCase(editAcceralateSchedule.pending, (state) => {
        state.editAcceralateSchedule_status = "loading";
        state.editAcceralateSchedule_data = null;
        state.editAcceralateSchedule_edited = false;
      })

      .addCase(editAcceralateSchedule.fulfilled, (state, { payload }) => {
        state.editAcceralateSchedule_status = "idle";
        if (payload?.status === 200) {
          // const baseState = current(state.getScheduleList_data);
          // produce(baseState, (draftState) => {
          //   let index = baseState.findIndex(
          //     (item) => item?._id === payload?.data?._id
          //   );

          //   draftState[index] = payload?.data;
          //   state.getScheduleList_data = current(draftState);
          // });
          state.editAcceralateSchedule_edited = true;
          toast(payload?.message, { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(editAcceralateSchedule.rejected, (state, action) => {
        state.editAcceralateSchedule_status = "idle";
      })
      //Delete
      .addCase(deleteAcceralateSchedule.pending, (state) => {
        state.deleteAcceralateSchedule_status = "loading";
      })

      .addCase(deleteAcceralateSchedule.fulfilled, (state, { payload }) => {
        state.deleteAcceralateSchedule_status = "idle";
        if (payload?.status === 200) {
          const baseState = current(state.getScheduleList_data);
          produce(baseState, (draftState) => {
            let index = baseState.findIndex(
              (item) => item?._id === payload?.data?._id
            );

            draftState.splice(index, 1);
            state.getScheduleList_data = current(draftState);
          });

          toast(payload?.message, { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(deleteAcceralateSchedule.rejected, (state, action) => {
        state.deleteAcceralateSchedule_status = "idle";
      });
  },
});

export default HomeSlice.reducer;
