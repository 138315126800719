import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import KeyboardDoubleArrowUpOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowUpOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import Header from "../Sharemodule/Header/Header";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import "./apmtdashboard.scss";
import {
  AddContainerList,
  deleteContainerList,
  EditContainerList,
  FetchScheduleDetails,
  FetchScheduleDetails_update,
  FetchSchedule_containerDetails,
  FetchSchedule_containerDetails_update,
  filterContainerList,
  searchMultipleList,
} from "../../Redux/AmptDashboard.slice";
import moment from "moment";
import TableSkeleton from "../Skeleton/TableSkeleton";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  ModalUnstyled,
  Popover,
  Select,
  Tabs,
  TextareaAutosize,
  Tooltip as MuiTooltip,
  Divider
} from "@mui/material";
import {
  AreaChart,
  Area,
  ResponsiveContainer,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  BarChart as Bchart,
} from "recharts";
import { Skeleton } from "@mui/material";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  calculateTimeRemaining,
  getDuration,
  getDuration2,
  getTimeLaps2,
  GMT_to_normal_time,
  Shift_Time_remaining,
  showTimeRemaining,
  truncateString,
} from "../../Utils/Utils";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Dropzone from "react-dropzone";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from '@mui/icons-material/Info';
import { CSVLink } from "react-csv";
import { makeStyles } from "@mui/styles";
import BarChart from "@mui/icons-material/BarChart";
import { data } from "../Appointments/Appointments";
import NoContentCard from "../NoContent/NoContentCard";
import DeleteIcon from '@mui/icons-material/Delete';
//import moment timezone/
const moment_tz = require("moment-timezone");

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "orange",
      height: 0,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "red",
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text,
}));

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 502,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "10px",
  p: 2,
  px: 2,
  pb: 3,
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 0px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.4px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    //background-color: ${blue[400]};
    color: ${blue[600]};
    border-bottom: 1px solid #2196f3;
    border-radius: 0px;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    color: #2196f3;
    outline: none;
    //background-color: ${blue[200]};
    border-bottom: 1px solid #000;
  }

  &.${tabUnstyledClasses.selected} {
    //background-color: ${blue[50]};
    color: ${blue[600]};
    border-bottom: 1px solid #2196f3;
    border-radius: 0px;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  color: rgba(0, 0, 0, 0.6);
  //background-color: ${blue[500]};
  //border-bottom:1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  // height: 10,
  //borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    //backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#e0e0e0",
  },
}));

//second
const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
  //height: 10,
  //borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    //backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    // borderRadius: 5,  backgroundColor: theme.palette.mode === "light" ? "#4caf50" : "#308fe8",
    backgroundColor: theme.palette.mode === "light" ? "#4caf50" : "#e0e0e0",
  },
}));

function TabPanel2(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Apmtdashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [shift, setShift] = useState(0);

  const { FetchScheduleDetails_data, FetchSchedule_containerDetails_status } =
    useSelector((s) => s.apmt_dashboard);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [barChartdata, setBarChartdata] = React.useState([]);

  const [newmodal, setnewmodal] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handle_Close = () => {
    setAnchorEl(null);
  };

  const pop_open = Boolean(anchorEl);
  const pop_id = pop_open ? "simple-popover" : undefined;

  const opennewmodalModal = () => {
    handle_Close();
    setnewmodal(true);
  };
  const closenewmodal = () => setnewmodal(false);

  useEffect(() => {
    dispatch(FetchScheduleDetails(id));
  }, []);

  useEffect(() => {
    if (FetchScheduleDetails_data !== null) {
      localStorage.setItem("time", FetchScheduleDetails_data?.timeRemaining);

      setShift(FetchScheduleDetails_data?.totalShifts);
      setValue(
        FetchScheduleDetails_data?.current_shift >= 0
          ? FetchScheduleDetails_data?.current_shift
          : 0
      );

      let temp = [];

      Object.keys(FetchScheduleDetails_data?.past_sla).map((key, index) => {
        let labelName = key.split("_")[1];
        let name = "";

        if (key.split("_")?.length > 2) {
          name = "Past 48 hr";
        } else {
          name = `${labelName} hrs`;
        }

        

        let obj = {
          label: name,
          count: FetchScheduleDetails_data?.past_sla[key],
        };
        temp.push(obj);
      });

      setBarChartdata(temp);
    }
  }, [FetchScheduleDetails_data]);

  useEffect(() => {
    if (
      FetchScheduleDetails_data !== null ||
      FetchScheduleDetails_data !== undefined
    ) {
      let interval;

      interval = setInterval(() => {
        dispatch(FetchScheduleDetails_update(id));
        dispatch(
          FetchSchedule_containerDetails_update({ acceleratescheduleId: id })
        );
      }, 500000);

      return () => clearInterval(interval);
    }
  }, [FetchScheduleDetails_data]);

  return (
    <>
      <Header />
      <div className="main-container">
        <div className="dashboard-contain">
          <div className="brade-camp brade-camp2 dashboard-top2 ">
            <>
              <Link to="/">Accelerate Hub </Link>
              <span>
                &nbsp; /&nbsp;
                <Link style={{ color: "black" }} to="/">
                  {FetchScheduleDetails_data === null &&
                  FetchSchedule_containerDetails_status === "loading" ? (
                    <Skeleton
                      sx={{ borderRadius: "30px" }}
                      variant="text"
                      width={150}
                    />
                  ) : (
                    FetchScheduleDetails_data?.code
                  )}
                </Link>
              </span>
            </>
          </div>
          <div className="dashboard-main">
            <TabsUnstyled defaultValue={1}>
              <div className="top-tab">
                <div className="first-tab">
                  <span className="first-text left-space">
                    <Link style={{ color: "black" }} to="/">
                      {FetchScheduleDetails_data === null &&
                      FetchSchedule_containerDetails_status === "loading" ? (
                        <Skeleton height={30} variant="rect" width={150} />
                      ) : (
                        FetchScheduleDetails_data?.code
                      )}
                    </Link>
                  </span>

                  {FetchScheduleDetails_data === null &&
                  FetchSchedule_containerDetails_status === "loading" ? (
                    <Skeleton
                      className="ms-2"
                      height={30}
                      sx={{ borderRadius: "30px" }}
                      variant="rect"
                      width={150}
                    />
                  ) : (
                    <Button
                      variant="text1"
                      className="scheduled-button middle-space"
                    >
                      {moment(FetchScheduleDetails_data?.program_start_date)
                        .tz("America/Los_Angeles")
                        .format("MMMM DD")}
                      -
                      {moment(FetchScheduleDetails_data?.program_end_date)
                        .tz("America/Los_Angeles")
                        .format("MMMM DD")}
                    </Button>
                  )}

                  {FetchScheduleDetails_data === null &&
                  FetchSchedule_containerDetails_status === "loading" ? (
                    <Skeleton
                      height={30}
                      className="ms-2"
                      sx={{ borderRadius: "30px" }}
                      variant="rect"
                      width={150}
                    />
                  ) : (
                    <>
                      {FetchScheduleDetails_data?.status === "Completed" ? (
                        <Button className="completed-button">
                          {FetchScheduleDetails_data?.status}
                        </Button>
                      ) : FetchScheduleDetails_data?.status ===
                        "In Progress" ? (
                        <Button className="inprogress-button">
                          {FetchScheduleDetails_data?.status}
                        </Button>
                      ) : (
                        <Button className="scheduled-button active-buttonRed ">
                          <span className="redText">
                            {FetchScheduleDetails_data?.status}
                          </span>
                        </Button>
                      )}
                    </>
                  )}
                </div>
                <div className="second-tab button-po">
                  <TabsList defaultValue={0}>
                    <Tab value={1}>DASHBOARD</Tab>
                    <Tab value={2}>Containers</Tab>
                    <div className="button-right-2">
                      {FetchScheduleDetails_data === null &&
                      FetchSchedule_containerDetails_status === "loading" ? (
                        <Skeleton
                          height={30}
                          className="ms-2"
                          sx={{ borderRadius: "2px" }}
                          variant="rect"
                          width={150}
                        />
                      ) : (
                        <GenerateReport
                          data={id}
                          name={FetchScheduleDetails_data?.code}
                        />
                      )}
                    </div>

                    <button
                      className="button-right ms-5"
                      aria-describedby={pop_id}
                      onClick={handleClick}
                    >
                      <h5 className="text-center">...</h5>
                    </button>
                    <Popover
                      id={pop_id}
                      open={pop_open}
                      anchorEl={anchorEl}
                      onClose={handle_Close}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <ul className="list-unstyled p-2">
                        <li>
                          <button
                            onClick={opennewmodalModal}
                            className="btn shadow-none"
                          >
                            <span>Add conatiner</span>
                          </button>
                        </li>
                      </ul>
                    </Popover>
                    <AddNewModal open={newmodal} handleClose={closenewmodal} />
                  </TabsList>
                </div>
              </div>

              <TabPanel value={1}>
                {FetchScheduleDetails_data === null &&
                FetchSchedule_containerDetails_status === "loading" ? (
                  <PageLoader />
                ) : (
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid className="dashboard-m" container spacing={2}>
                      <Grid item lg={9} md={9} xs={12}>
                        <Grid container spacing={3}>
                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Item>
                              <Timer
                                FetchScheduleDetails_data={
                                  FetchScheduleDetails_data
                                }
                              />
                            </Item>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Item>
                              <div className="dash-board-t mid">
                                <div className="space-main">
                                  <span className="time-text">
                                    {FetchScheduleDetails_data !== null
                                      ? FetchScheduleDetails_data?.progress
                                      : "0"}
                                    %
                                  </span>
                                  <span className="r-text">Complete</span>
                                </div>
                                <Box className="red-bar" sx={{ flexGrow: 1 }}>
                                  <BorderLinearProgress1
                                    className="ff"
                                    variant="determinate"
                                    value={FetchScheduleDetails_data?.progress}
                                  />
                                </Box>
                              </div>
                            </Item>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Item>
                              <div className="dash-board-t mid right-graph">
                                <div className="space-main">
                                  <span className="time-text">
                                    {FetchScheduleDetails_data !== null ? (
                                      FetchScheduleDetails_data?.avgDrainVelocity
                                    ) : (
                                      <Skeleton variant="text" width={60} />
                                    )}
                                    <KeyboardDoubleArrowUpOutlinedIcon className="doble-icon" />
                                  </span>
                                  <span className="r-text">Drain velocity</span>
                                </div>
                                <div className="g-image">
                                  <DraftVelocityChart
                                    data={
                                      FetchScheduleDetails_data?.avgDrainVelocityArr
                                    }
                                  />
                                </div>
                              </div>
                            </Item>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid className="overview-main">
                            <Item className="overview-t">Overview</Item>

                            <Grid container className="pile-m" spacing={3}>
                              <Grid item lg={4} sm={4} md={4} xs={12}>
                                <div className="pile-text">
                                  <Item className="pile-text-h">pre-pile</Item>
                                  <ul>
                                    <li>
                                      <span className="heading-text1">
                                        {" "}
                                        {FetchScheduleDetails_data !== null ? (
                                          FetchScheduleDetails_data?.onVessel
                                        ) : (
                                          <Skeleton variant="text" width={60} />
                                        )}
                                      </span>
                                      <span className="heading-text2">
                                        On vessel
                                      </span>
                                    </li>
                                    <li>
                                      <span className="heading-text1">
                                        {FetchScheduleDetails_data !== null ? (
                                          FetchScheduleDetails_data?.onHold
                                        ) : (
                                          <Skeleton variant="text" width={60} />
                                        )}
                                      </span>
                                      <span className="heading-text2">
                                        On hold
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </Grid>
                              <Grid item lg={4} sm={4} md={4} xs={12}>
                                <div className="pile-text">
                                  <Item className="pile-text-h">
                                    Discharged
                                  </Item>
                                  <ul>
                                    <li>
                                      <span className="heading-text1">
                                        {FetchScheduleDetails_data !== null ? (
                                          FetchScheduleDetails_data?.inPile
                                        ) : (
                                          <Skeleton variant="text" width={60} />
                                        )}
                                      </span>
                                      <span className="heading-text2">
                                        Wheels
                                      </span>
                                    </li>
                                    <li>
                                      <span className="heading-text1">
                                        {FetchScheduleDetails_data !== null ? (
                                          FetchScheduleDetails_data?.wheeled
                                        ) : (
                                          <Skeleton variant="text" width={60} />
                                        )}
                                      </span>
                                      <span className="heading-text2">
                                        In pile
                                      </span>
                                    </li>
                                    <li>
                                      <span className="heading-text1">
                                        {FetchScheduleDetails_data !== null ? (
                                          FetchScheduleDetails_data?.missorted
                                        ) : (
                                          <Skeleton variant="text" width={60} />
                                        )}
                                        <PriorityHighOutlinedIcon className="p-icon" />
                                      </span>
                                      <span className="heading-text2">
                                        Missorted
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </Grid>

                              <Grid item lg={4} sm={4} md={4} xs={12}>
                                <div className="pile-text">
                                  <Item className="pile-text-h">out-gate</Item>
                                  <ul>
                                    <li>
                                      <span className="heading-text1">
                                        {FetchScheduleDetails_data !== null ? (
                                          FetchScheduleDetails_data?.totalOut
                                        ) : (
                                          <Skeleton variant="text" width={60} />
                                        )}
                                      </span>
                                      <span className="heading-text2">
                                        Total out
                                      </span>
                                    </li>
                                    <li>
                                      <span className="heading-text1 heading-textNew">
                                        {}
                                        {FetchScheduleDetails_data !== null ? (
                                          FetchScheduleDetails_data?.lastOut !==
                                          null ? (
                                            moment(
                                              FetchScheduleDetails_data?.lastOut
                                            )
                                              .utc()
                                              .format("MMM DD,HH:mm")
                                          ) : (
                                            "N/A"
                                          )
                                        ) : (
                                          <Skeleton variant="text" width={60} />
                                        )}
                                      </span>
                                      <span className="heading-text2">
                                        Last out
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </Grid>

                              {/* <Grid item lg={3} md={3} sm={3} xs={12}>
                              <div className="pile-text pile-textright">
                                <Item className="pile-text-h">IN-GATE</Item>
                                <ul>
                                  <li>
                                    <span className="heading-text1">
                                      18<sub>min</sub>
                                    </span>
                                    <span className="heading-text2">
                                      Last in
                                    </span>
                                  </li>
                                  <li>
                                    <span className="heading-text1">10</span>
                                    <span className="heading-text2">
                                      Total in
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </Grid> */}
                            </Grid>
                          </Grid>
                          {/* {FetchScheduleDetails_data !== null && (
                            <AppointmentsSection shift={shift} />
                          )} */}

                          <Grid className="overview-main">
                            <TabsUnstyled scrollButtons="auto" value={value}>
                              <div className="tab-b-main overview-t">
                                <div className="tab-b-left">
                                  Shift Information
                                </div>
                                <div className="tab-b-right">
                                  <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    className={classes.tabs}
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                  >
                                    {Array.from({ length: shift }, (_, i) => (
                                      <Tab
                                        value={i}
                                        indicatorColor="red"
                                        {...a11yProps(i)}
                                      >
                                        <span
                                          className={value === i && "textblack"}
                                        >
                                          {" "}
                                          Shift {`${i + 1}`}
                                        </span>
                                      </Tab>
                                    ))}
                                  </Tabs>
                                </div>
                              </div>

                              {Array.from({ length: shift }, (_, i) => (
                                <TabPanel2 value={i} index={i}>
                                  <TabPanel value={i} index={i}>
                                    <ShiftTabPanel
                                      value={i}
                                      FetchScheduleDetails_data={
                                        FetchScheduleDetails_data
                                      }
                                    />
                                  </TabPanel>
                                </TabPanel2>
                              ))}
                            </TabsUnstyled>
                            {/* </Grid> */}
                          </Grid>

                         
                        </Grid>
                      </Grid>
                      <Grid item lg={3} md={3} xs={12}>
                        <Grid container className="right-panel">
                          <h4>General</h4>
                          <DashboardRightPanel
                            FetchScheduleDetails_data={
                              FetchScheduleDetails_data
                            }
                            shift={shift}
                            data={id}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid className="overview-main">
                            <div className="tab-b-main d-flex justify-content-between p-2 overview-t">
                              <div className="tab-b-left">Containers Overdue</div>
                              <div className="">
                              <MuiTooltip title="information">
                              <IconButton>
        <InfoIcon />
      </IconButton>
                              </MuiTooltip>    
  
   
                              </div>
                            </div>
                            

                            <Grid item xs={12}>
                              <div
                                style={{
                                  overflowX: "auto",
                                }}
                              >
                                <ResponsiveContainer
                                  minWidth={950}
                                  height={250}
                                >
                                  <Bchart height={250} data={barChartdata}>
                                    <XAxis
                                      axisLine={false}
                                      style={{
                                        fontSize: "12px",
                                        letterSpacing: "0.4px",
                                      }}
                                      dy={15}
                                      tick={{ fill: "#0b79d0" }}
                                      dataKey="label"
                                    />
                                    <Tooltip />
                                    <Bar
                                      barSize={20}
                                      dataKey="count"
                                      fill="#303f9f"
                                    />
                                  </Bchart>
                                </ResponsiveContainer>
                              </div>
                            </Grid>
                          </Grid>
                  </Box>
                )}
              </TabPanel>

              <TabPanel value={2}>
                <ContainerList />
              </TabPanel>
            </TabsUnstyled>
          </div>
        </div>
      </div>
    </>
  );
}

const AppointmentsSection = ({ shift }) => {
  const { FetchScheduleDetails_data } = useSelector((s) => s.apmt_dashboard);
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid className="overview-main">
      <TabsUnstyled scrollButtons="auto" value={value}>
        <div className="tab-b-main overview-t">
          <div className="tab-b-left">Appointments</div>
          <div className="tab-b-right">
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.tabs}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {Array.from({ length: shift }, (_, i) => (
                <Tab value={i} indicatorColor="red" {...a11yProps(i)}>
                  <span className={value === i && "textblack"}>
                    Shift {`${i + 1}`}
                  </span>
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>

        {Array.from({ length: shift }, (_, i) => (
          <TabPanel2 value={i} index={i}>
            <TabPanel value={i} index={i}>
              <AppointmentsSectionTabPanel value={i} />
            </TabPanel>
          </TabPanel2>
        ))}
      </TabsUnstyled>
    </Grid>
  );
};

const AppointmentsSectionTabPanel = ({ value, data }) => {
  const { FetchScheduleDetails_data } = useSelector((s) => s.apmt_dashboard);

  return (
    <Grid container className="b-tab-con">
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {FetchScheduleDetails_data?.shiftWiseInfo[value]
          ?.appointment_with_time_slot === null ? (
          <NoContentCard msg="No Appointments" />
        ) : (
          <Table>
            <TableRow>
              <TableCell variant="head">
                Shift {value + 1} Appointments windows{" "}
              </TableCell>
              {Object.keys(
                FetchScheduleDetails_data?.shiftWiseInfo[value]
                  ?.appointment_with_time_slot
              ).map((key, index) => {
                return (
                  <TableCell key={index}>
                    {
                      FetchScheduleDetails_data?.shiftWiseInfo[value]
                        ?.appointment_with_time_slot[key]?.time
                    }
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell variant="head"># no of Appointments</TableCell>
              {Object.keys(
                FetchScheduleDetails_data?.shiftWiseInfo[value]
                  ?.appointment_with_time_slot
              ).map((key, index) => {
                return (
                  <TableCell key={index}>
                    {
                      FetchScheduleDetails_data?.shiftWiseInfo[value]
                        ?.appointment_with_time_slot[key]?.counts
                    }
                  </TableCell>
                );
              })}
            </TableRow>
          </Table>
        )}
      </Grid>
    </Grid>
  );
};

const DraftVelocityChart = React.memo(({ data }) => {
  return (
    <ResponsiveContainer width={"99%"} height={100}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="4%" stopColor="#4caf50" stopOpacity={0.8} />
            <stop offset="80%" stopColor="#4caf50" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="value"
          stroke="#4caf50"
          fillOpacity={1}
          fill="url(#colorPv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
});

const MySkel = ({ height }) => {
  return (
    <Skeleton
      sx={{
        borderRadius: "5px",
      }}
      variant="rect"
      width="100%"
      height={height}
    />
  );
};

const PageLoader = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid className="dashboard-m" container spacing={2}>
        <Grid item lg={9} md={9} xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <Item>
                <MySkel variant="rect" width="100%" height={200} />
              </Item>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <Item>
                <MySkel variant="rect" width="100%" height={200} />
              </Item>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <Item>
                <MySkel variant="rect" width="100%" height={200} />
              </Item>
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="overview-main">
              <Grid container className="pile-m" spacing={3}>
                <Grid item lg={4} sm={4} md={4} xs={12}>
                  <MySkel variant="rect" width="100%" height={200} />
                </Grid>
                <Grid item lg={4} sm={4} md={4} xs={12}>
                  <MySkel variant="rect" width="100%" height={200} />
                </Grid>
                <Grid item lg={4} sm={4} md={4} xs={12}>
                  <MySkel variant="rect" width="100%" height={200} />
                </Grid>
              </Grid>
            </Grid>
            <Grid className="overview-main">
              <MySkel variant="rect" width="100%" height={200} />
            </Grid>
            <Grid className="overview-main">
              <MySkel variant="rect" width="100%" height={200} />
            </Grid>
            <Grid className="overview-main">
              <MySkel variant="rect" width="100%" height={200} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3} md={3} xs={12}>
          <Grid container className="right-panel">
            <MySkel variant="rect" width="100%" height="100%" />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const Timer = ({ FetchScheduleDetails_data }) => {
  const [timer, set_timer] = useState(null);
  const [paused, setPaused] = useState(false);
  let today = new Date();

  const setTimer = () => {
    // just showing the remaining time
    set_timer(showTimeRemaining(FetchScheduleDetails_data?.timeRemaining));
    setPaused(true);
    // if (
    //   moment_tz(today).tz("America/Los_Angeles") >=
    //   moment_tz(FetchScheduleDetails_data?.shiftWiseInfo[0]?.start).tz(
    //     "America/Los_Angeles"
    //   )
    // ) {
    //   setPaused(true);
    // }
  };

  useEffect(() => {
    if (
      FetchScheduleDetails_data !== null ||
      FetchScheduleDetails_data !== undefined
    ) {
      let interval;

      //if day number is sunday stop the timer
      if (today.getDay() !== 0) {
        // if time remaining is >0 then show timer else consider it as completed
        if (FetchScheduleDetails_data?.timeRemaining > 0) {
          // if no shift is active stop the timer
          if (FetchScheduleDetails_data?.current_shift > 0) {
            //if starting shift is second then start the timer from start time ->second shift
            if (FetchScheduleDetails_data?.starting_shift === "second") {
              // until second shift start form todays time stop the timer
              if (
                moment_tz(today).tz("America/Los_Angeles") >=
                moment_tz(
                  FetchScheduleDetails_data?.shiftWiseInfo[0]?.start
                ).tz("America/Los_Angeles")
              ) {
                interval = setInterval(() => {
                  const temp = calculateTimeRemaining();
                  set_timer(temp);
                }, 2000);
              } else {
                setTimer();
              }
            } else {
              interval = setInterval(() => {
                const temp = calculateTimeRemaining();
                set_timer(temp);
              }, 2000);
            }
          } else {
            setTimer();
          }
        } else {
          setTimer();
        }
      } else {
        setTimer();
      }

      return () => clearInterval(interval);
    }
  }, [FetchScheduleDetails_data]);

  return (
    <div className="dash-board-t">
      <div className="space-main">
        <span className="time-text">
          {timer === null
            ? "00:00:00"
            : `${timer?.hours}:${timer.minutes}:${timer.seconds}`}
        </span>
        <span className="r-text">
          {FetchScheduleDetails_data?.timeRemaining > 0 ? (
            paused ? (
              <>
                Time remaining <span className="text-muted">(Paused)</span>{" "}
              </>
            ) : (
              "Time remaining"
            )
          ) : (
            "Completed"
          )}
        </span>
      </div>

      <Box sx={{ flexGrow: 1 }}>
        <BorderLinearProgress
          className="blue-bar"
          variant="determinate"
          value={Math.ceil(
            ((FetchScheduleDetails_data?.totalTime -
              FetchScheduleDetails_data?.timeRemaining) /
              FetchScheduleDetails_data?.totalTime) *
              100
          )}
        />
      </Box>
    </div>
  );
};

const TimerForShift = ({ value, start, end, FetchScheduleDetails_data }) => {
  const [time, setTime] = useState(null);

  useEffect(() => {
    if (
      FetchScheduleDetails_data !== null ||
      FetchScheduleDetails_data !== undefined
    ) {
      let interval;

      if (FetchScheduleDetails_data?.shiftWiseInfo[value]?.currentShift) {
        interval = setInterval(() => {
          let x = Shift_Time_remaining(
            FetchScheduleDetails_data?.shiftWiseInfo[value]?.start,
            FetchScheduleDetails_data?.shiftWiseInfo[value]?.end
          );

          setTime(x);
        }, 2000);
      }

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <>
      <div className="space-main">
        {FetchScheduleDetails_data !== undefined ? (
          <span className="time-text-b">
            {time !== null
              ? `${time?.hours}:${time?.minutes}:${time?.seconds}`
              : getDuration2(
                  FetchScheduleDetails_data?.shiftWiseInfo[value]
                    ?.timeRemainingObj
                )}
          </span>
        ) : (
          <span className="time-text-b">
            <Skeleton variant="text" width={25} /> :{" "}
            <Skeleton variant="text" width={25} /> :{" "}
            <Skeleton variant="text" width={25} />
          </span>
        )}
        <span className="r-text-b">
          {value < 0 ? `Shift ${value + 1} not started` : ""}
        </span>

        {value >= FetchScheduleDetails_data?.current_shift ? (
          <span className="r-text-b">Remaining in shift {value + 1}</span>
        ) : (
          <span className="r-text-b">Shift {value + 1} completed</span>
        )}
      </div>
    </>
  );
};

const ShiftTabPanelAppoinmentChart=({value})=>{
  const { FetchScheduleDetails_data } = useSelector((s) => s.apmt_dashboard);
  const [chartData,setChartData]=useState([])

  const getChartData=()=>{
    let temp=[];
    Object.keys(FetchScheduleDetails_data?.shiftWiseInfo[value]?.appointment_with_time_slot).map((key,index)=>{
      let tempObj={
        time:FetchScheduleDetails_data?.shiftWiseInfo[value]
        ?.appointment_with_time_slot[key]?.time,
        sucess:FetchScheduleDetails_data?.shiftWiseInfo[value]
        ?.appointment_with_time_slot[key]?.counts,
        missed:FetchScheduleDetails_data?.shiftWiseInfo[value]
        ?.appointment_with_time_slot[key]?.missed
      }
      temp.push(tempObj)
    })

    setChartData(temp)
  }

  const data4 = [
    {
      time: "8.00",
      sucess: 4000,
      missed: 2400,
    },
    {
      time: "10.00",
      sucess: 3000,
      missed: 1398,
    },
    {
      time: "12.00",
      sucess: 2000,
      missed: 9800,
    },
    {
      time: "13.00",
      sucess: 2780,
      missed: 3908,
    },
  
  ];

  useEffect(()=>{
    if( FetchScheduleDetails_data?.shiftWiseInfo[value]?.appointment_with_time_slot!==null){
      getChartData()
    }
  },[value])
  return (
    <>
    
    {

      FetchScheduleDetails_data?.shiftWiseInfo[value]
          ?.appointment_with_time_slot === null ? 
          <NoContentCard msg="No Appointments" />:<ResponsiveContainer width={"99%"} height={250}>
              <Bchart width={730} height={250} data={chartData}>
              
               
                <XAxis
                  axisLine={false}
                  style={{
                    fontSize: "12px",
                    letterSpacing: "0.4px",
                  }}
                  dy={15}
                  tick={{ fill: "#0b79d0" }}
                  dataKey="time"
                />
              

                <Bar barSize={20} dataKey="sucess" fill="#303f9f" />
                <Bar barSize={20} dataKey="missed" fill="#e31b0c" />
              </Bchart>
            </ResponsiveContainer>
    }
      
    </>
  )
}

const ShiftTabPanel = ({
  value,
  data,
  currentShift,
  FetchScheduleDetails_data,
}) => {
  // useEffect(() => {
  //   if (
  //     FetchScheduleDetails_data !== null ||
  //     FetchScheduleDetails_data !== undefined
  //   ) {
  //     localStorage.setItem(
  //       `shift-${value}`,
  //       FetchScheduleDetails_data?.shiftWiseInfo[value]?.timeRemaining
  //     );
  //   }
  // }, [FetchScheduleDetails_data]);

  return (
    <>
    <Grid container className="b-tab-con">
    <Grid item lg={4} md={4} sm={4} xs={12}>
      {value + 1 <= FetchScheduleDetails_data?.completed_shift ? (
        <div className="space-main">
          <span className="time-text-b">00:00:00</span>
          <span className="r-text-b">Shift {value + 1} completed</span>
        </div>
      ) : (
        <>
          {FetchScheduleDetails_data?.current_shift < 0 ? (
            <div className="space-main">
              {/* <span className="time-text-b">00:00:00</span> */}
              <span className="time-text-b">
                {getDuration2(
                  FetchScheduleDetails_data?.shiftWiseInfo[value]
                    ?.timeRemainingObj
                )}
              </span>
              <span className="r-text-b">Shift {value + 1} not started</span>
            </div>
          ) : (
            <TimerForShift
              value={value}
              start={FetchScheduleDetails_data?.shiftWiseInfo[value]?.start}
              end={FetchScheduleDetails_data?.shiftWiseInfo[value]?.end}
              FetchScheduleDetails_data={FetchScheduleDetails_data}
            />
          )}
        </>
      )}
    </Grid>
    <Grid item lg={4} md={4} sm={4} xs={12}>
      <span className="time-text-b">
        {FetchScheduleDetails_data !== null ? (
          FetchScheduleDetails_data?.shiftWiseInfo[value]?.outRateShift
        ) : (
          <Skeleton variant="text" width={60} />
        )}
        <sub>
          min
          <KeyboardDoubleArrowDownOutlinedIcon className="down-arrow" />
        </sub>
      </span>
      <span className="r-text-b">Out rate</span>
    </Grid>
    <Grid item lg={4} md={4} sm={4} xs={12}>
      <span className="time-text-b">
        {" "}
        {FetchScheduleDetails_data !== null ? (
          FetchScheduleDetails_data?.shiftWiseInfo[value]?.totalOutShift
        ) : (
          <Skeleton variant="text" width={60} />
        )}
      </span>
      <span className="r-text-b">Total out</span>
    </Grid>

    <Grid item lg={12} md={12} sm={12} xs={12}>
    <div className="mt-5"> <Divider  />
    </div>
          <div className="pt-3">
          <span className="r-text-b">Shift {value + 1} Appointments </span>
          </div>
  
      <ShiftTabPanelAppoinmentChart value={value}/>
    </Grid>


  </Grid>


  
  </>
    
  );
};

const DashboardRightPanel = React.memo(
  ({ FetchScheduleDetails_data, shift, data }) => {
    return (
      <>
        <ul>
          <li>
            <span className="left-text">Group Code</span>

            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                FetchScheduleDetails_data?.code
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li>

          {FetchScheduleDetails_data?.conventional_or_tis ===
            "Conventional" && (
            <li>
              <Accordion
                elevation={0}
                sx={{ margin: "0 !important" }}
                style={{ width: "100%" }}
                expanded={true}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content1"
                  id="panel1a-header1"
                  s
                  sx={{ padding: "0 !important" }}
                >
                  <span className="left-text">Pile Location</span>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0 !important" }}>
                  <span className="left-text1 ">
                    {FetchScheduleDetails_data?.pilelocation_data?.length >
                    0 ? (
                      <>
                        {FetchScheduleDetails_data?.pilelocation_data?.map(
                          (_item, index) => {
                            return (
                              <>
                                {`${_item?.pile_location}`}
                                {index <
                                  FetchScheduleDetails_data?.pilelocation_data
                                    ?.length -
                                    1 && ","}
                              </>
                            );
                          }
                        )}
                      </>
                    ) : (
                      "No pile location"
                    )}
                  </span>
                </AccordionDetails>
              </Accordion>
            </li>
          )}

          <li>
            <span className="left-text">Vessel name</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                FetchScheduleDetails_data?.vesselName === "" ? (
                  "N/A"
                ) : (
                  FetchScheduleDetails_data?.vesselName
                )
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li>
          <li>
            <span className="left-text">Vessel ETA date</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                moment(FetchScheduleDetails_data?.vessel_eta_date).format(
                  "DD/MM/YYYY"
                )
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li>
          <li>
            <span className="left-text">Voyage</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                FetchScheduleDetails_data?.voyage
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li>
          <li>
            <span className="left-text">SCAC</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                FetchScheduleDetails_data?.scac === "" ? (
                  "N/A"
                ) : (
                  FetchScheduleDetails_data?.scac
                )
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li>
          <li>
            <span className="left-text">Duration</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                getDuration(FetchScheduleDetails_data?.totalTime)
              ) : (
                <Skeleton variant="text" width={60} />
              )}
              {}
            </span>
          </li>
          <li>
            <span className="left-text">Containers</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                FetchScheduleDetails_data?.containercount
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li>
          <li>
            <span className="left-text">Containers remaining</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                FetchScheduleDetails_data?.containercount -
                FetchScheduleDetails_data?.container_used
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li>
          {/* <li>
            <span className="left-text">Wheels</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                FetchScheduleDetails_data?.wheeled
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li> */}
          <li>
            <span className="left-text"># of Shifts</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                `${shift}`
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li>
          <li>
            <span className="left-text">Shift 1 contact name</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                FetchScheduleDetails_data?.shift_1_terminal_contact_name
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li>
          <li>
            <span className="left-text">Shift 1 contact number</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                FetchScheduleDetails_data?.shift_1_terminal_contact_number
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li>
          <li>
            <span className="left-text">Shift 2 contact name</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                FetchScheduleDetails_data?.shift_2_terminal_contact_name
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li>
          <li>
            <span className="left-text">Shift 2 contact number</span>
            <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                FetchScheduleDetails_data?.shift_2_terminal_contact_number
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span>
          </li>

          <li>
            {/* <span className="left-text">Notes</span> */}
            <Accordion
              elevation={0}
              sx={{ margin: "0 !important" }}
              style={{ width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                s
                sx={{ padding: "0 !important" }}
              >
                <span className="left-text">Notes</span>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0 !important" }}>
                <span className="left-text1">
                  {FetchScheduleDetails_data?.notes === ""
                    ? "No notes"
                    : FetchScheduleDetails_data?.notes}
                </span>
              </AccordionDetails>
            </Accordion>
            {/* <span className="left-text1">
              {FetchScheduleDetails_data !== null ? (
                moment(FetchScheduleDetails_data?.vessel_eta_date).format(
                  "DD/MM/YYYY"
                )
              ) : (
                <Skeleton variant="text" width={60} />
              )}
            </span> */}
          </li>
          {/* <li>
            <GenerateReport data={data} />
          </li> */}
        </ul>
      </>
    );
  }
);

const GenerateReport = React.memo(({ data, name }) => {
  const [loading, setLoading] = useState(false);
  const [csv, setcsv] = useState([]);
  const { FetchSchedule_containerDetails_data, FetchScheduleDetails_data } =
    useSelector((s) => s.apmt_dashboard);
  const ref = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchSchedule_containerDetails({ acceleratescheduleId: data }));
  }, []);

  const handleCreateReport = async () => {
    setLoading(true);

    let csvData = [
      [
        "Container",
        "Status",
        "Pile Location",
        "Warnings",
        "Driver",
        "Discharge Time",
        "Gate-out Time",
        "Time Lapsed",
        "Appointments",
        "Trucker SCAC",
        "Note",
      ],
    ];

    // if(){

    // }

    if (FetchScheduleDetails_data?.conventional_or_tis === "TIS") {
      csvData = [
        [
          "Container",
          "Status",
          "Driver",
          "Discharge Time",
          "Gate-out Time",
          "Time Lapsed",
          "Appointments",
          "Trucker SCAC",
          "Note",
        ],
      ];
    }

    FetchSchedule_containerDetails_data?.map((item) => {
      let obj = [
        item?.container,
        item?.status === "ECOUT" ? "MISSORTED" : item?.status,
        item?.yardLocation,
        item?.warnings === "" ? "N/A" : item?.warnings,
        item?.driver === "" ? "N/A" : item?.driver,

        item?.discharge_date_time === null
          ? "N/A"
          : `${moment(item?.discharge_date_time).format(
              "DD/MM/yyyy"
            )} ,${GMT_to_normal_time(item?.discharge_date_time)},${moment(
              item?.discharge_date_time
            )
              .tz("America/Los_Angeles")
              .format("z")}`,
        item?.gate_out_time === null
          ? "N/A"
          : `${moment(item?.gate_out_time).format(
              "DD/MM/yyyy"
            )} ,${GMT_to_normal_time(item?.gate_out_time)},${moment(
              item?.discharge_date_time
            )
              .tz("America/Los_Angeles")
              .format("z")}`,
        item?.time_lapsed <= 0 ? "N/A" : getTimeLaps2(item?.time_lapsed),
        item?.appointmentDateTime === null ||
        item?.appointmentDateTime === undefined
          ? "N/A"
          : `${moment(item?.appointmentDateTime).tz("America/Los_Angeles").format("DD/MM/yyyy")} ,${GMT_to_normal_time(item?.appointmentDateTime)},${moment(item?.appointmentDateTime).tz("America/Los_Angeles").format("z")}`,
        item?.scac === "" ? "N/A" : item?.scac,
        item?.note === "" ? "N/A" : item?.note,
      ];

      if (FetchScheduleDetails_data?.conventional_or_tis === "TIS") {
        obj = [
          item?.container,
          item?.status === "ECOUT" ? "MISSORTED" : item?.status,

          item?.driver === "" ? "N/A" : item?.driver,

          item?.discharge_date_time === null
            ? "N/A"
            : `${moment(item?.discharge_date_time).format(
                "DD/MM/yyyy"
              )} ,${GMT_to_normal_time(item?.discharge_date_time)}`,
          item?.gate_out_time === null
            ? "N/A"
            : `${moment(item?.gate_out_time).format(
                "DD/MM/yyyy"
              )} ,${GMT_to_normal_time(item?.gate_out_time)}`,
          item?.time_lapsed <= 0 ? "N/A" : getTimeLaps2(item?.time_lapsed),
          item?.appointmentDateTime === null ||
          item?.appointmentDateTime === undefined
            ? "N/A"
            : moment(item?.appointmentDateTime)
                .tz("America/Los_Angeles")
                .format("DD/MM/yyyy"),
          item?.scac === "" ? "N/A" : item?.scac,
          item?.note === "" ? "N/A" : item?.note,
        ];
      }

      csvData.push(obj);
    });
    setLoading(false);

    setcsv(csvData);
  };

  return (
    <>
      <Button
        disabled={
          FetchSchedule_containerDetails_data === null ||
          FetchSchedule_containerDetails_data?.length === 0
        }
        className="bar-icon2 "
        onClick={handleCreateReport}
        variant="outlined"
        color="primary"
      >
        <CSVLink
          className="colorBlue"
          filename={`${name}.csv`}
          data={csv}
          ref={ref}
          // target="_blank"
        >
          {loading ? (
            <CircularProgress color="inherit" className="mx-2" size={15} />
          ) : (
            <BarChartIcon />
          )}
          Export to excel
        </CSVLink>
      </Button>
    </>
  );
});

const ContainerList = React.memo(({ rows }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const open_status = Boolean(anchorEl);
  const popid = open_status ? "simple-popover" : undefined;
  const [filter, setfilter] = useState("select");
  const {
    FetchSchedule_containerDetails_status,
    FetchSchedule_containerDetails_data,
    deleteContainerList_deleted,
    EditContainerList_edited,
    FetchScheduleDetails_data,
    AddContainerList_added,
  } = useSelector((s) => s.apmt_dashboard);
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handle_Close = () => {
    setAnchorEl(null);
  };

  const handleFilter = (e) => {
    setfilter(e.target.value);
    dispatch(filterContainerList(e.target.value));
  };

  useEffect(() => {
    if (deleteContainerList_deleted || AddContainerList_added) {
      dispatch(FetchSchedule_containerDetails({ acceleratescheduleId: id }));
    }
  }, [
    deleteContainerList_deleted,
    EditContainerList_edited,
    AddContainerList_added,
  ]);

  return (
    <>
      <div className="dashboard-appointment-con dashboard-appointment-contab2">
        <div className="mui-right-tab"></div>
        <div className="mui-right-tab right-bg-t">
          <div className="search-box">
            {" "}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "13ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={() => <SearchIcon className="search-icon" />}
                onClick={handleClickOpen}
              >
                <span className="text-capitalize">Search</span>
              </Button>

              <SearchModal open={open} handleClose={handleClose} />
            </Box>
          </div>{" "}
          <FormControl className="t-width">
            <InputLabel htmlFor="grouped-native-select">Status</InputLabel>
            <Select
              id="grouped-native-select"
              label="Grouping"
              value={filter}
              onChange={handleFilter}
            >
              <MenuItem value="select">All</MenuItem>
              <MenuItem value="outgated">Outgated</MenuItem>
              <MenuItem value="missorted">Missorted</MenuItem>
              <MenuItem value="updating">Updating</MenuItem>
              <MenuItem value="wheeled">Wheeled</MenuItem>
              <MenuItem value="pile">Pile</MenuItem>
              <MenuItem value="On Vessel">On Vessel</MenuItem>
              <MenuItem value="unavailable">Unavailable</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <TableContainer
        className="table-container mt-2"
        component={Paper}
        style={{ maxHeight: "100vh" }}
      >
        <Table stickyHeader sx={{ minWidth: 950 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="c-head">Container</TableCell>
              <TableCell
                // aria-describedby={popid}
                // onClick={handleClick}
                className="c-head"
                align="center"
              >
                Status
                {/* <span>
                  <KeyboardArrowDownIcon />
                </span> */}
              </TableCell>
              <Popover
                id={popid}
                open={open_status}
                anchorEl={anchorEl}
                onClose={handle_Close}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <MenuItem onClick={(e) => handleFilter("Outgated")}>
                  Outgated
                </MenuItem>
                <MenuItem onClick={(e) => handleFilter("Missorted")}>
                  Missorted
                </MenuItem>
              </Popover>

              {FetchScheduleDetails_data?.conventional_or_tis !== "TIS" && (
                <TableCell className="c-head" align="center">
                  Pile Location
                </TableCell>
              )}

              {FetchScheduleDetails_data?.conventional_or_tis !== "TIS" && (
                <TableCell className="c-head" align="center">
                  Warnings
                </TableCell>
              )}

              <TableCell className="c-head" align="center">
                Driver
              </TableCell>
              <TableCell className="c-head" align="center">
                Discharge Time
              </TableCell>
              <TableCell className="c-head" align="center">
                Gate-out Time
              </TableCell>
              <TableCell className="c-head" align="center">
                Time Lapsed
              </TableCell>
              <TableCell className="c-head" align="center">
                Appointments
              </TableCell>
              <TableCell className="c-head" align="center">
                Trucker SCAC
              </TableCell>
              <TableCell className="c-head" align="center">
                Note
              </TableCell>
              <TableCell className="c-head" align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sc>
            {FetchSchedule_containerDetails_status === "loading" ||
            FetchSchedule_containerDetails_data === null ? (
              <>
                {[1, 1, 1, 1, 1, 1].map((item, index) => {
                  return <TableSkeleton key={index} count={11} />;
                })}
              </>
            ) : (
              <>
                {FetchSchedule_containerDetails_data?.length > 0 ? (
                  <>
                    {FetchSchedule_containerDetails_data?.map((row) => {
                      return <TableRows key={row?._id} item={row} />;
                    })}
                  </>
                ) : null}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {FetchSchedule_containerDetails_data?.length === 0 && (
        <NoContentCard msg="No data found" />
      )}
    </>
  );
});

const SearchModal = ({ open, handleClose, Data }) => {
  const [csv, setcsv] = useState(null);
  const [containerNumber, setcontainerNumber] = useState("");

  const [err_containerNumber, setcontainerNumber_err] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  const { AddContainerList_status, AddContainerList_added } = useSelector(
    (s) => s.apmt_dashboard
  );

  const handleContainerNumber = (e) => {
    e.preventDefault();
    setcontainerNumber(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(searchMultipleList(containerNumber));
    handleClose();
  };

  // useEffect(() => {
  //   AddContainerList_added && handleClose();
  // }, [AddContainerList_added]);

  useEffect(() => {
    setcontainerNumber("");
  }, [open]);

  useEffect(() => {
    setcontainerNumber("");
  }, []);

  return (
    <StyledModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
    >
      <Box className="modal-main" sx={style}>
        <form onSubmit={handleSubmit}>
          <h2 id="unstyled-modal-title">
            Search Container
            <button className="cross-button" onClick={handleClose}>
              <ClearOutlinedIcon />
            </button>
          </h2>
          <Grid container spacing={2}>
            <Grid className="con-number" item xs={12}>
              <Item>
                <TextareaAutosize
                  value={containerNumber}
                  onChange={handleContainerNumber}
                  aria-label="empty textarea"
                  placeholder="Search containers numbers separated by comma"
                />
              </Item>
              <span className="error-t errText">{err_containerNumber}</span>
            </Grid>
            <Grid className="last-modal" item xs={12}>
              <Item>
                <Button type="submit" variant="text" className="schedule-b">
                  Search
                </Button>
              </Item>
              {containerNumber?.length > 0 && (
                <Item>
                  <span
                    type="button"
                    onClick={() => setcontainerNumber("")}
                    variant="outlined"
                    className="text-center text-danger"
                  >
                    Clear all
                  </span>
                </Item>
              )}
            </Grid>
          </Grid>
        </form>
      </Box>
    </StyledModal>
  );
};

const AddNewModal = ({ open, handleClose, Data }) => {
  const [csv, setcsv] = useState(null);
  const [containerNumber, setcontainerNumber] = useState("");
  const [err_csv, setcsv_err] = useState(null);
  const [err_containerNumber, setcontainerNumber_err] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  const { AddContainerList_status, AddContainerList_added } = useSelector(
    (s) => s.apmt_dashboard
  );

  const handleContainerNumber = (e) => {
    setcsv(null);
    e.preventDefault();
    setcontainerNumber(e.target.value);
    // if (containerNumber?.split(/\r\n|\r|\n/)?.length > 25) {
    //   setcontainerNumber_err("Cannot add more than 25");
    //   if (key === "Backspace" || key === "Delete") {
    //     setcontainerNumber(e.target.value);
    //   }
    // } else {
    //   setcontainerNumber_err(null);
    //   setcontainerNumber(e.target.value);
    // }
    // if (containerNumber !== "") {
    //   setcsv(null);
    // }
  };

  // const handleKeyPress = (e) => {
  //   setkey(e.key);
  // };
  const handleFileInput = (acceptedFiles) => {
    if (
      acceptedFiles[0]?.type === ".csv" ||
      acceptedFiles[0]?.type === "text/csv" ||
      acceptedFiles[0]?.type === "application/vnd.ms-excel" ||
      acceptedFiles[0]?.type === "text/x-csv" ||
      acceptedFiles[0]?.type === "application/x-csv" ||
      acceptedFiles[0]?.type === "text/comma-separated-values" ||
      acceptedFiles[0]?.type === "text/x-comma-separated-values" ||
      acceptedFiles[0]?.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setcsv(acceptedFiles[0]);
      setcontainerNumber("");
      setcsv_err(null);
    } else {
      setcsv(null);
      setcsv_err("Add only .csv ,.xlxs file");
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (csv === null && containerNumber === "") {
      setcsv_err("Enter csv or container number");
      return;
    }
    const form = new FormData();
    form.append("acceleratescheduleId", id);

    csv !== null && form.append("csv", csv);
    containerNumber !== "" && form.append("container", containerNumber);
    dispatch(AddContainerList(form));
  };
  useEffect(() => {
    AddContainerList_added && handleClose();
  }, [AddContainerList_added]);
  return (
    <StyledModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
    >
      <Box className="modal-main" sx={style}>
        <form onSubmit={handleSubmit}>
          <h2 id="unstyled-modal-title">
            Add Container
            <button className="cross-button" onClick={handleClose}>
              <ClearOutlinedIcon />
            </button>
          </h2>
          <Grid container spacing={2}>
            <Grid className="last" item xs={12}>
              <Item>
                <Dropzone
                  acceptedFiles={[
                    ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  ]}
                  onDrop={handleFileInput}
                >
                  {({ getRootProps, getInputProps }) => (
                    <label htmlFor="icon-button-file" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <IconButton aria-label="upload picture" component="span">
                        <FileUploadIcon />
                        <span>Upload (.csv, .xlx)</span>
                      </IconButton>
                    </label>
                  )}
                </Dropzone>
              </Item>
              <p style={{ display: "flex", flexDirection: "column" }}>
                {err_csv !== null && (
                  <span className="error-t errText">{err_csv}</span>
                )}
                <a
                  href="/assets/images/test.xlsx"
                  download="sampleCsv"
                  className="linkText"
                >
                  Download sample csv
                </a>
                <p>{csv?.name}</p>
              </p>
            </Grid>

            {/* <span item xs={12} align="center">
              <p>{csv?.name}</p>
            </span> */}
            <Grid item xs={12} align="center">
              Or
            </Grid>
            <Grid className="con-number" item xs={12}>
              {/* <span className="e-text">
                Enter up to 25 tracking numbers one per line
              </span> */}
              <Item>
                <TextareaAutosize
                  value={containerNumber}
                  onChange={handleContainerNumber}
                  // onKeyDown={handleKeyPress}
                  aria-label="empty textarea"
                  placeholder="Enter Your Containers Numbers"
                />
              </Item>
              <span className="error-t errText">{err_containerNumber}</span>
            </Grid>
            <Grid className="last-modal" item xs={12}>
              <Item>
                <Button
                  disabled={AddContainerList_status === "loading"}
                  type="submit"
                  variant="text"
                  className="schedule-b"
                >
                  {AddContainerList_status === "loading" && (
                    <CircularProgress
                      sx={{ mx: 2 }}
                      color="inherit"
                      size={15}
                    />
                  )}
                  ADD CONTAINER
                </Button>
              </Item>
            </Grid>
          </Grid>
        </form>
      </Box>
    </StyledModal>
  );
};

const TableRows = ({ item }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [edit, setEdit] = React.useState(false);
  const pop_open = Boolean(anchorEl);
  const id = pop_open ? "simple-popover" : undefined;
  const dispatch = useDispatch();
  const { FetchScheduleDetails_data } = useSelector((s) => s.apmt_dashboard);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handle_Close = () => {
    setAnchorEl(null);
  };

  const openEditModal = () => {
    handle_Close();
    setEdit(true);
  };
  const closeEditModal = () => setEdit(false);

  const handleDelete = () => {
    handle_Close();
    dispatch(deleteContainerList(item?._id));
  };

  return (
    <>
      <TableRow
        key={item?._id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
        className="cursor"
      >
        <TableCell component="th" scope="row">
          {item?.container}
        </TableCell>

        {item?.status === "" ? (
          <TableCell align="center">...</TableCell>
        ) : (
          <>
            {item?.status === "PILE" ? (
              <TableCell align="center">
                <Button className="active-button12">{item?.status}</Button>
              </TableCell>
            ) : (
              <TableCell align="center">
                <Button className="active-button11">
                  {item?.status === "ECOUT" ? "MISSORTED" : item?.status}
                </Button>
              </TableCell>
            )}
          </>
        )}

        {FetchScheduleDetails_data?.conventional_or_tis !== "TIS" && (
          <TableCell align="center">
            {item?.yardLocation === "" ? "..." : item?.yardLocation}
          </TableCell>
        )}

        {FetchScheduleDetails_data?.conventional_or_tis !== "TIS" && (
          <TableCell align="center">
            {item?.warnings === "" ? (
              "N/A"
            ) : (
              <Button className="active-buttonRed ">
                <span className="redText">{item?.warnings}</span>
              </Button>
            )}
          </TableCell>
        )}

        <TableCell align="center">...</TableCell>
        <TableCell align="center">
          {item?.discharge_date_time === null
            ? "..."
            : `${moment(item?.discharge_date_time)
                .tz("America/Los_Angeles")
                .format("DD/MM/yyyy")} ,
          ${GMT_to_normal_time(item?.discharge_date_time)},${moment(
                item?.discharge_date_time
              )
                .tz("America/Los_Angeles")
                .format("z")}`}
        </TableCell>
        <TableCell align="center">
          {item?.gate_out_time === null
            ? "..."
            : `${moment(item?.gate_out_time)
                .tz("America/Los_Angeles")
                .format("DD/MM/yyyy")} ,
          ${GMT_to_normal_time(item?.gate_out_time)},${moment(
                item?.gate_out_time
              )
                .tz("America/Los_Angeles")
                .format("z")}`}
        </TableCell>
        <TableCell align="center">
          {item?.time_lapsed === null ||
          item?.time_lapsed === "" ||
          item?.time_lapsed === 0
            ? "..."
            : getTimeLaps2(item?.time_lapsed)}
        </TableCell>

        <TableCell align="center">
          {item?.appointmentDateTime === null ||
          item?.appointmentDateTime === undefined
            ? "N/A"
            : `${moment(item?.appointmentDateTime)
                .tz("America/Los_Angeles")
                .format("DD/MM/yyyy")} ,
          ${GMT_to_normal_time(item?.appointmentDateTime)},${moment(
                item?.appointmentDateTime
              )
                .tz("America/Los_Angeles")
                .format("z")}`}
        </TableCell>
        <TableCell align="center">
          {" "}
          {item?.scac === "" ? "N/A" : item?.scac}
        </TableCell>
        <TableCell align="center">
          {item?.note === "" ? "..." : truncateString(item?.note, 20)}
        </TableCell>

        <TableCell align="center">
          <Button variant="text" aria-describedby={id} onClick={handleClick}>
            <h5>...</h5>
          </Button>
          <Popover
            id={id}
            open={pop_open}
            anchorEl={anchorEl}
            onClose={handle_Close}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <ul className="list-unstyled p-2">
              <li>
                <button onClick={openEditModal} className="btn shadow-none">
                  <span>Edit</span>
                </button>
              </li>
              <li>
                <button onClick={handleDelete} className="btn shadow-none">
                  <span>Delete</span>
                </button>
              </li>
            </ul>
          </Popover>
          <EditModal open={edit} handleClose={closeEditModal} Data={item} />
        </TableCell>
      </TableRow>
    </>
  );
};

const EditModal = ({ open, handleClose, Data }) => {
  // const [pileLocation, setPileLocation] = useState(Data?.pile_locationId?._id);

  // const [container, setcontainer] = useState(Data?.container);
  // const [discharge_date_time, setdischarge_date_time] = useState(
  //   Data?.discharge_date_time
  // );
  // const [driver, setdriver] = useState(Data?.driver);
  // const [warnings, setwarnings] = useState(Data?.warnings);
  // const [gate_out_time, setgate_out_time] = useState(Data?.gate_out_time);
  const [note, setnote] = useState(Data?.note);
  // const [status, setstatus] = useState(Data?.status);
  // const [csv, setcsv] = useState(null);
  // const [containerNumber, setcontainerNumber] = useState(Data?.containerNumber);

  // const [err_container, setcontainer_err] = useState("");
  // const [err_pileLocation, setpileLocation_err] = useState("");
  // const [err_program_start_date, setprogram_start_date_err] = useState("");
  // const [err_program_end_date, setprogram_end_date_err] = useState("");
  // const [err_voyage, setvoyage_err] = useState("");
  // const [err_csv, setcsv_err] = useState(null);
  // const [err_containerNumber, setcontainerNumber_err] = useState("");
  // const [err_no_of_shifts, setno_of_shifts_err] = useState("");
  const [err_note, setnote_err] = useState(null);
  const dispatch = useDispatch();
  const { EditContainerList_status, EditContainerList_edited } = useSelector(
    (s) => s.apmt_dashboard
  );

  const {
    getPileLocations_data,
    addNewScheduleStatus,

    editAcceralateSchedule_status,
    editAcceralateSchedule_data,
    editAcceralateSchedule_edited,
  } = useSelector((s) => s.home);

  // const handleChangePileLocation = (e) => {
  //   setPileLocation(e.target.value);
  // };
  // const handleContainerNumber = (e) => {
  //   if (containerNumber.split(/\r\n|\r|\n/).length >= 25) {
  //     setcontainerNumber_err("Cannot add more than 25");
  //     return;
  //   }
  //   if (containerNumber !== "") {
  //     setcsv(null);
  //   }
  //   setcontainerNumber(e.target.value);
  // };

  // const handleChange = (newValue) => {
  //   setdischarge_date_time(newValue);
  // };

  // const handlegate_out_time = (newValue) => {
  //   setgate_out_time(newValue);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    setnote_err(null);
    if (note === "") {
      setnote_err("Enter note");
      return;
    }

    const form = new FormData();

    form.append("container_id", Data?._id);
    // form.append("pile_locationId", pileLocation);
    // form.append("status", status);
    // form.append("gate_out_time", moment(gate_out_time).toISOString(true));
    // form.append("driver", driver);
    // form.append(
    //   "discharge_date_time",
    //   moment(discharge_date_time).toISOString(true)
    // );
    // form.append("warnings", warnings);

    form.append("note", note);

    dispatch(EditContainerList(form));
  };

  useEffect(() => {
    EditContainerList_edited && handleClose();
  }, [EditContainerList_edited]);

  return (
    <>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box className="modal-main" sx={style}>
          <form onSubmit={handleSubmit}>
            <h2 id="unstyled-modal-title">
              Add Note
              <button className="cross-button" onClick={handleClose}>
                <ClearOutlinedIcon />
              </button>
            </h2>
            <Grid container spacing={2}>
              {/* <Grid className="group-code-main" item xs={6} lg={6}>
                <Item>
                  <Input
                    value={container}
                    onChange={(e) => setcontainer(e.target.value)}
                    placeholder="Container Number"
                    // inputProps={ariaLabel}
                  />
                </Item>
                <span className="error-text">{err_container}</span>
              </Grid> */}
              {/* <Grid className="p-date edit-con-p" item xs={6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      label="p"
                      onChange={(e) => setstatus(e.target.value)}
                    >
                      <MenuItem value="">Select status</MenuItem>
                      <MenuItem value="Added">Added</MenuItem>
                      <MenuItem value="On Vessel">On Vessel</MenuItem>
                      <MenuItem value="Discharged from Vessel">
                        Discharged from Vessel
                      </MenuItem>
                      <MenuItem value="Customs Hold">Customs Hold</MenuItem>
                      <MenuItem value="Missorted">Missorted</MenuItem>
                      <MenuItem value="Pile">In Pile</MenuItem>
                      <MenuItem value="Outgated">Outgated</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <span className="error-text">{err_pileLocation}</span>
              </Grid> */}

              {/* <Grid item className="pile-location-main" lg={6} xs={6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Pile Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pileLocation}
                      label="p"
                      onChange={handleChangePileLocation}
                    >
                      {getPileLocations_data !== null &&
                        getPileLocations_data?.length > 0 &&
                        getPileLocations_data?.map((item) => {
                          return (
                            <MenuItem key={item?._id} value={item?._id}>
                              {item?.pile_location}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
                <span className="error-text">{err_pileLocation}</span>
              </Grid> */}
              {/* <Grid item lg={6} xs={6} className="vessel-voyage">
                <Item>
                  <Input
                    placeholder="Driver"
                    value={driver}
                    onChange={(e) => setdriver(e.target.value)}
                    // inputProps={ariaLabel}
                  />
                </Item>
                <span className="error-text">{err_voyage}</span>
              </Grid> */}

              {/* <Grid item lg={6} xs={6} className="vessel-voyage edit-con">
                <Item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      value={discharge_date_time}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField label="Discharge Time" {...params} />
                      )}
                    />
                    
                  </LocalizationProvider>
                </Item>
                <span className="error-text">{err_voyage}</span>
              </Grid> */}

              {/* <Grid item lg={6} xs={6} className="vessel-voyage edit-con">
                <Item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      value={gate_out_time}
                      onChange={handlegate_out_time}
                      renderInput={(params) => (
                        <TextField label="Gate out Time" {...params} />
                      )}
                    />
                   
                  </LocalizationProvider>
                </Item>
                <span className="error-text">{err_voyage}</span>
              </Grid> */}

              {/* <Grid item lg={12} xs={12} className="vessel-voyage edit-con">
                <Item>
                  <Input
                    placeholder="Warnings"
                    value={warnings}
                    onChange={(e) => setwarnings(e.target.value)}
                    // inputProps={ariaLabel}
                  />
                </Item>
                <span className="error-text">{err_voyage}</span>
              </Grid> */}

              {/* <Grid item lg={12} xs={12} className="shifts">
                <Box sx={{ minWidth: 120 }}></Box>
              </Grid> */}

              {/* <Grid className="last" item xs={12}>
                <Item>
                  <Dropzone
                    acceptedFiles={[
                      ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ]}
                    onDrop={(acceptedFiles) => {
                      setcsv(acceptedFiles[0]);
                      setcontainerNumber("");
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <label htmlFor="icon-button-file" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <IconButton
                          aria-label="upload picture"
                          component="span"
                        >
                          <FileUploadIcon />
                          <span>Upload (.csv, .xlx)</span>
                        </IconButton>
                      </label>
                    )}
                  </Dropzone>
                </Item>
                <label>{err_csv}</label>
              </Grid> */}

              {/* <Grid item xs={12} align="center">
                <p>{csv?.name}</p>
              </Grid> */}
              {/* <Grid item xs={12} align="center">
                Or
              </Grid> */}
              <Grid className="con-number" item xs={12}>
                {/* <span className="e-text">Add note</span> */}
                <Item>
                  <TextareaAutosize
                    value={note}
                    onChange={(e) => setnote(e.target.value)}
                    aria-label="empty textarea"
                    placeholder="Enter notes"
                  />
                </Item>
                <span className="error-t error-text">{err_note}</span>
              </Grid>
              <Grid className="last-modal" item xs={12}>
                <Item>
                  <Button
                    disabled={EditContainerList_status === "loading"}
                    type="submit"
                    variant="text"
                    className="schedule-b"
                  >
                    {EditContainerList_status === "loading" && (
                      <CircularProgress
                        color="inherit"
                        sx={{ mx: 2 }}
                        size={15}
                      />
                    )}
                    ADD NOTE
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </form>
        </Box>
      </StyledModal>
    </>
  );
};
