import { configureStore, t } from "@reduxjs/toolkit";
// import globalReducer from "./globalSlice";
import HomeSlice from "./Home.slice";
import LoginSlice from "./Login.slice";
import ApmtDashboardSlice from './AmptDashboard.slice'
import TerminalSlice from "./TerminalSlice";

export const store = configureStore({
  reducer: {
   
    login: LoginSlice,
    home: HomeSlice,
    apmt_dashboard:ApmtDashboardSlice,
    terminal:TerminalSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
