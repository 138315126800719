import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import produce, { current } from "immer";
import { toast } from "react-toastify";
import { Accelerate_Schedule_end_points } from "../api/api_end_points";
import axiosInstance from "../api/AxiosInstance";


export const FetchScheduleDetails = createAsyncThunk(
  "/FetchScheduleDetails",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${Accelerate_Schedule_end_points.schedule_details}/${data}`
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);
export const FetchScheduleDetails_update = createAsyncThunk(
  "/FetchScheduleDetails_update",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${Accelerate_Schedule_end_points.schedule_details}/${data}`
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const generateToken = createAsyncThunk(
  "/generateToken",
  async (data, { rejectWithValue }) => {
    let temp = {
      client_id: "1x8jXmolAKC2ZAOiL2rHhPsY1a0N5kuN",
      client_secret: "5A4a6wGIeV9AWuiU",
    };
    try {
      let x = "/user/tokengenerate";
      const res = await axiosInstance.post(x, temp);

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const FetchSchedule_containerDetails = createAsyncThunk(
  "/FetchSchedule_containerDetails",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        Accelerate_Schedule_end_points.list,
        data
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const FetchSchedule_containerDetails_update = createAsyncThunk(
  "/FetchSchedule_containerDetails_update",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        Accelerate_Schedule_end_points.list,
        data
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const AddContainerList = createAsyncThunk(
  "/AddContainerList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        Accelerate_Schedule_end_points.AddContainerList,
        data
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const EditContainerList = createAsyncThunk(
  "/EditContainerList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        Accelerate_Schedule_end_points.updateConatiner,
        data
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

// export const generateReport = createAsyncThunk(
//   "/generateReport",
//   async (data, { rejectWithValue }) => {
//     try {
//       const res = await axiosInstance.post(
//         Accelerate_Schedule_end_points.updateConatiner,
//         data
//       );

//       return res.data;
//     } catch (error) {
//       return rejectWithValue([], error);
//     }
//   }
// );

export const deleteContainerList = createAsyncThunk(
  "/deleteContainerList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${Accelerate_Schedule_end_points.deleteContainer}/${data}`
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

const initialState = {
  FetchScheduleDetails_status: "idle",
  FetchScheduleDetails_data: null,
  FetchSchedule_containerDetails_status: "idle",
  FetchSchedule_containerDetails_data: null,
  FetchSchedule_containerDetails_data2: null,

  AddContainerList_status: "idle",
  AddContainerList_added: false,
  EditContainerList_status: "idle",
  EditContainerList_edited: false,
  deleteContainerList_status: "idle",
  deleteContainerList_deleted: false,
  generateReport_status: "idle",
  generateReport_data: null,
};

const ApmtDashboardSlice = createSlice({
  name: "ApmtDashboardSlice",
  initialState,
  reducers: {
    filterContainerList: (state, { payload }) => {
      //filter by status from FetchSchedule_containerDetails_data2
      state.FetchSchedule_containerDetails_status = "loading";

      if (payload === "select") {
        state.FetchSchedule_containerDetails_data =
          state.FetchSchedule_containerDetails_data2;
      } else {
        const filteredContainerList =
          state.FetchSchedule_containerDetails_data2.filter(
            (container) =>
              container.status.toLowerCase() === payload.toLowerCase()
          );
        state.FetchSchedule_containerDetails_data = filteredContainerList;
      }
      state.FetchSchedule_containerDetails_status = "idle";
    },
    searchMultipleList: (state, { payload }) => {
      //check payload is empty
      state.FetchSchedule_containerDetails_status = "loading";
      if (payload !== "") {
        let y = payload.split(",");
        let x = state.FetchSchedule_containerDetails_data2.filter((container) =>
          y.includes(container.container)
        );
        state.FetchSchedule_containerDetails_data = x;
      } else {
        state.FetchSchedule_containerDetails_data =
          state.FetchSchedule_containerDetails_data2;
      }
      state.FetchSchedule_containerDetails_status = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(FetchScheduleDetails.pending, (state) => {
        state.FetchScheduleDetails_status = "loading";

        state.FetchScheduleDetails_data = null;
      })

      .addCase(FetchScheduleDetails.fulfilled, (state, { payload }) => {
        state.FetchScheduleDetails_status = "idle";
        state.message = payload?.message;

        if (payload?.status === 200) {
          state.FetchScheduleDetails_data = payload?.data;
        } else {
        }
      })
      .addCase(FetchScheduleDetails.rejected, (state, action) => {
        state.FetchScheduleDetails_status = "idle";

        state.message = "Something went wrong";
      })

      //update after 5 mins
      .addCase(FetchScheduleDetails_update.pending, (state) => {})
      .addCase(FetchScheduleDetails_update.fulfilled, (state, { payload }) => {
        state.FetchScheduleDetails_status = "idle";
        state.message = payload?.message;

        if (payload?.status === 200) {
          state.FetchScheduleDetails_data = payload?.data;
        } else {
        }
      })
      .addCase(FetchScheduleDetails_update.rejected, (state, action) => {
        state.message = "Something went wrong";
      })

      //
      .addCase(FetchSchedule_containerDetails.pending, (state) => {
        state.FetchSchedule_containerDetails_status = "loading";
        state.FetchSchedule_containerDetails_data = null;
        state.FetchSchedule_containerDetails_data2 = null;
        state.EditContainerList_edited = false;
        state.AddContainerList_added = false;
      })

      .addCase(
        FetchSchedule_containerDetails.fulfilled,
        (state, { payload }) => {
          state.FetchSchedule_containerDetails_status = "idle";
          state.message = payload?.message;

          if (payload?.status === 200) {
            state.FetchSchedule_containerDetails_data = payload?.data;
            state.FetchSchedule_containerDetails_data2 = payload?.data;
          } else {
            state.FetchSchedule_containerDetails_data = [];
          }
        }
      )
      .addCase(FetchSchedule_containerDetails.rejected, (state, action) => {
        state.FetchSchedule_containerDetails_status = "idle";
        state.message = "Something went wrong";
      })
      //update contaner after 5 min
      .addCase(FetchSchedule_containerDetails_update.pending, (state) => {
        state.EditContainerList_edited = false;
        state.AddContainerList_added = false;
      })

      .addCase(
        FetchSchedule_containerDetails_update.fulfilled,
        (state, { payload }) => {
          state.message = payload?.message;

          if (payload?.status === 200) {
            state.FetchSchedule_containerDetails_data = payload?.data;
          } else {
            state.FetchSchedule_containerDetails_data = [];
          }
        }
      )
      .addCase(
        FetchSchedule_containerDetails_update.rejected,
        (state, action) => {
          state.FetchSchedule_containerDetails_status = "idle";
          state.message = "Something went wrong";
        }
      )

      // Add conatiner
      .addCase(AddContainerList.pending, (state) => {
        state.AddContainerList_status = "loading";
        state.AddContainerList_added = null;
      })

      .addCase(AddContainerList.fulfilled, (state, { payload }) => {
        state.AddContainerList_status = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.AddContainerList_added = true;
        } else {
          toast(state.message, { type: "error" });
        }
      })
      .addCase(AddContainerList.rejected, (state, action) => {
        state.AddContainerList_status = "idle";
        state.message = "Something went wrong";
        toast(state.message, { type: "error" });
      })
      //update conatiner

      .addCase(EditContainerList.pending, (state) => {
        state.EditContainerList_status = "loading";
        state.EditContainerList_edited = false;
      })

      .addCase(EditContainerList.fulfilled, (state, { payload }) => {
        state.EditContainerList_status = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.EditContainerList_edited = true;
          const baseState = current(state.FetchSchedule_containerDetails_data);
          produce(baseState, (draftState) => {
            let index = baseState.findIndex(
              (item) => item?._id === payload?.data?._id
            );

            draftState[index] = payload?.data;
            state.FetchSchedule_containerDetails_data = current(draftState);
            toast(state.message, { type: "success" });
          });
        } else {
          toast(state.message, { type: "error" });
        }
      })
      .addCase(EditContainerList.rejected, (state, action) => {
        state.EditContainerList_status = "idle";
        state.message = "Something went wrong";
        toast(state.message, { type: "error" });
      })

      //delete container
      .addCase(deleteContainerList.pending, (state) => {
        state.deleteContainerList_status = "loading";
        state.deleteContainerList_deleted = false;
      })

      .addCase(deleteContainerList.fulfilled, (state, { payload }) => {
        state.deleteContainerList_status = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          // state.deleteContainerList_deleted = true;
          const baseState = current(state.FetchSchedule_containerDetails_data);
          produce(baseState, (draftState) => {
            let index = baseState.findIndex(
              (item) => item?._id === payload?.data?._id
            );

            draftState.splice(index, 1);
            state.FetchSchedule_containerDetails_data = current(draftState);
          });
          toast(payload?.message, { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(deleteContainerList.rejected, (state, action) => {
        state.deleteContainerList_status = "idle";
        state.message = "Something went wrong";
      })
      //
      .addCase(generateToken.pending, (state) => {
        state.message = "Something went wrong";
      })

      .addCase(generateToken.fulfilled, (state, { payload }) => {
        state.message = "Something went wrong";
      })
      .addCase(generateToken.rejected, (state, action) => {
        state.message = "Something went wrong";
      });
  },
});

export const { filterContainerList, searchMultipleList } =
  ApmtDashboardSlice.actions;

export default ApmtDashboardSlice.reducer;
