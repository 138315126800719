//create slice
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import produce, { current } from "immer";
import { toast } from "react-toastify";
import { terminal_end_points } from "../api/api_end_points";
import axiosInstance from "../api/AxiosInstance";

export const fetchTerminalList = createAsyncThunk(
  "/FetchScheduleDetails",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        terminal_end_points.terminalList,
        data
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const editTerminalList = createAsyncThunk(
  "/editTerminalList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        `${terminal_end_points.edit_terminal}/${data.id}`,
        data._data
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const addTerminalList = createAsyncThunk(
  "/addTerminalList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        terminal_end_points.add_terminal,
        data
      );

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

const initialState = {
  terminalList: null,
  terminalListStatus: "idle",
  terminalListmsg: "",

  edit_terminalList: null,
  edit_terminalListStatus: "idle",
  edit_terminalListmsg: "",
  edit_terminal_edited: false,

  add_terminalList: null,
  add_terminalListStatus: "idle",
  add_terminalListmsg: "",
  add_terminal_added: false,
};

const terminalSlice = createSlice({
  name: "terminal",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTerminalList.pending]: (state, action) => {
      state.terminalListStatus = "loading";
      state.terminalList = null;
      state.edit_terminal_edited = false;
      state.add_terminal_added=false
    },
    [fetchTerminalList.fulfilled]: (state, { payload }) => {
      state.terminalListLoading = false;
      state.terminalListStatus = "idle";

      if (payload?.status === 200) {
        state.terminalList = payload?.data;
      } else {
        state.terminalList = [];
      }
    },
    [fetchTerminalList.rejected]: (state, action) => {
      state.terminalListLoading = false;
      state.terminalListStatus = "idle";
    },
    //add terminal
    [addTerminalList.pending]: (state, action) => {
        state.add_terminalListStatus = "loading";
        state.add_terminalList = null;
      },
      [addTerminalList.fulfilled]: (state, { payload }) => {
        state.add_terminalListLoading = false;
        state.add_terminalListStatus = "idle";
        if (payload?.status === 200) {
          state.add_terminal_added = true;
          toast.success(payload.message);
        } else {
          toast.error(payload.message);
        }
      },
      [addTerminalList.rejected]: (state, action) => {
        state.add_terminalListLoading = false;
        state.add_terminalListStatus = "idle";
      },
    //edit terminal
    [editTerminalList.pending]: (state, action) => {
      state.edit_terminalListStatus = "loading";
      state.edit_terminalList = null;
    },
    [editTerminalList.fulfilled]: (state, { payload }) => {
      state.edit_terminalListLoading = false;
      state.edit_terminalListStatus = "idle";
      if (payload?.status === 200) {
        state.edit_terminal_edited = true;
        toast.success(payload.message);
      } else {
        toast.error(payload.message);
      }
    },
    [editTerminalList.rejected]: (state, action) => {
      state.edit_terminalListLoading = false;
      state.edit_terminalListStatus = "idle";
    },
  },
});

export default terminalSlice.reducer;
