import React, { useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { checkLoggedIn } from "./Redux/Login.slice";
import Home from "./Components/Home/Home";
import Apmtdashboard from "./Components/Apmtdashboard/apmtdashboard";
import Login from "./Components/Sharemodule/login/login";
import Appointments from "./Components/Appointments/Appointments";
import Terminals from "./Components/Terminals/Terminal";
import Header from "./Components/Sharemodule/Header/Header";

// const Home = lazy(() => import("./Components/Home/Home"));
// const Apmtdashboard = lazy(() =>
//   import("./Components/Apmtdashboard/apmtdashboard")
// );
// const Appointments = lazy(() =>
//   import("./Components/Appointments/Appointments")
// );
// const Terminals = lazy(() => import("./Components/Terminals/Terminal"));
// const Login = lazy(() => import("./Components/Sharemodule/login/login"));

const PrivateRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
          <>
            <Redirect to="login" />
          </>
        )
      }
    />
  );
};

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkLoggedIn());
  }, []);
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        closeButton={true}
        toastStyle={{
          backgroundColor: "black",
          color: "white",
        }}
      />
      <Router>
        {/* <Header/> */}
        {/* <Suspense fallback={<div>Loading...</div>}> */}
          <Switch>
            <PrivateRoutes component={Home} path="/" exact />
            {/* <Route component={Address} path="/demo_address" exact /> */}
            <Route component={Apmtdashboard} path="/Apmtdashboard/:id" exact />
            <Route component={Terminals} path="/terminals" exact />
            <Route component={Appointments} path="/appointments/:id" exact />
            <Route
              component={Apmtdashboard}
              path="/apmtdashboard-m/:id"
              exact
            />
            <Route component={Login} path="/login" exact />
          </Switch>
        {/* </Suspense> */}
      </Router>
    </div>
  );
}

export default App;
