import React, { useState, useEffect } from "react";
import "./Appintments.scss";
import "../Home/Home.scss";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabUnstyled from "@mui/base/TabUnstyled";
import Button from "@mui/material/Button";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import Header from "../../Components/Sharemodule/Header/Header";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Chip,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Pagination,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function createData(Carrier, Total, TotalMissed, Adherence) {
  return { Carrier, Total, TotalMissed, Adherence };
}

const rows = [
  { id: "Data 1", id2: 10, id3: 20, id4: "30 %" },
  { id: "Data 2", id2: 10, id3: 20, id4: "30 %" },
  { id: "Data 3", id2: 10, id3: 20, id4: "30 %" },
  { id: "Data 4", id2: 10, id3: 20, id4: "30 %" },
  { id: "Data 5", id2: 10, id3: 20, id4: "30 %" },
  { id: "Data 6", id2: 10, id3: 20, id4: "30 %" },
  { id: "Data 7", id2: 10, id3: 20, id4: "30 %" },
];

const rows2 = [
  {
    id: 1,
    Appointment: "123456",
    type: "IP",
    Date: "Nov 17, 2021",
    Slot: "7.30-8.30",
    Status: "Missed",
    Trucker: "Simplified Transport",
    Container: "Container",
  },
  {
    id: 2,
    Appointment: "123456",
    type: "IP",
    Date: "Nov 17, 2021",
    Slot: "7.30-8.30",
    Status: "Missed",
    Trucker: "Simplified Transport",
    Container: "Container",
  },
  {
    id: 3,
    Appointment: "123456",
    type: "IP",
    Date: "Nov 17, 2021",
    Slot: "7.30-8.30",
    Status: "Missed",
    Trucker: "Simplified Transport",
    Container: "Container",
  },
  {
    id: 4,
    Appointment: "123456",
    type: "IP",
    Date: "Nov 17, 2021",
    Slot: "7.30-8.30",
    Status: "Missed",
    Trucker: "Simplified Transport",
    Container: "Container",
  },
  {
    id: 5,
    Appointment: "123456",
    type: "IP",
    Date: "Nov 17, 2021",
    Slot: "7.30-8.30",
    Status: "Missed",
    Trucker: "Simplified Transport",
    Container: "Container",
  },
  {
    id: 6,
    Appointment: "123456",
    type: "IP",
    Date: "Nov 17, 2021",
    Slot: "7.30-8.30",
    Status: "Missed",
    Trucker: "Simplified Transport",
    Container: "Container",
  },
  {
    id: 7,
    Appointment: "123456",
    type: "IP",
    Date: "Nov 17, 2021",
    Slot: "7.30-8.30",
    Status: "Missed",
    Trucker: "Simplified Transport",
    Container: "Container",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const columns = [
  { field: "Appointment", headerName: "Appointment #", width: 250 },
  {
    field: "type",
    headerName: "Appointment Type",
    width: 250,
  },
  {
    field: "Date",
    headerName: "Date",
    width: 150,
  },
  {
    field: "Slot",
    headerName: "Slot",
    type: "number",
    width: 180,
  },
  {
    field: "Status",
    headerName: "Status",
    type: "number",
    width: 150,

    renderCell: (cellValues) => {
      return (
        <Button className="active-buttonRed ">
          <span className="redText">{cellValues?.value}</span>
        </Button>
      );
    },
  },
  {
    field: "Trucker",
    headerName: "Trucker",
    width: 200,
  },
  {
    field: "Container",
    headerName: "Container",
    width: 200,
  },
  {
    field: "",
    headerName: "",
    type: "number",
    width: 150,
    renderCell: (cellValues) => {
      return <Button className="active-button">...</Button>;
    },
  },
];

export const data = [
  {
    name: "SUNDAY",
    uv: 4000,
    pv: 2400,
  },
  {
    name: "MONDAY",
    uv: 3000,
    pv: 1398,
  },
  {
    name: "TUESDAY",
    uv: 2000,
    pv: 9800,
  },
  {
    name: "WEDNESDAY",
    uv: 2780,
    pv: 3908,
  },
  {
    name: "THURSDAY",
    uv: 1890,
    pv: 4800,
  },
  {
    name: "FRIDAY",
    uv: 2390,
    pv: 3800,
  },
  {
    name: "SATURDAY",
    uv: 3490,
    pv: 4300,
  },
];

const useStyles = makeStyles({
  dataGrid: {
    width: "100%",
  },
});

export default function Appointments() {
  const [open1, setOpen1] = useState(false);
  const classes = useStyles();
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open, setOpen] = React.useState(false);
  const [searchText, setsearchText] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlesearchText = (e) => {
    setsearchText(e.target.value);
  };

  const resetsearchText = () => {
    setsearchText("");
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <Header />
      <div class="main-container">
        <div class="brade-camp">
          <a href="/">Home</a>
          <span> / Terminals </span>/ Pier 400
        </div>
        <div class="dashboard-contain">
          <div className="apointment-tab">
            <div class="appoinment-top">
              <Button
                onClick={handleClickOpen}
                className="right-appointments-button"
              >
                <h5>...</h5>
              </Button>
              <h1>Pier 400</h1>
              <TabsUnstyled defaultValue={0}>
                <TabsListUnstyled className="appointment-tab-top">
                  <TabUnstyled>DASHboard</TabUnstyled>
                  <TabUnstyled>appointments</TabUnstyled>
                </TabsListUnstyled>
                <TabPanelUnstyled className="appointment-tab-top-con" value={0}>
                  <div className="dashboard-appointment-con">
                    <Button
                      className="last-month-button custom-button"
                      variant="text"
                      // onClick={handleOpen1}
                    >
                      custom date range{" "}
                      <CalendarTodayOutlinedIcon className="calendar-icon" />
                    </Button>
                    <Modal
                      open={open1}
                      onClose={handleClose1}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      id="kk"
                    >
                      <Box className="m-t" sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Terminal Configuration
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Box sx={{ minWidth: 180 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Terminal name
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={""}
                                      label="Terminal name"
                                      //onChange={handleChange}
                                    >
                                      <MenuItem value={10}>Pier 400</MenuItem>
                                      <MenuItem value={20}>Twenty</MenuItem>
                                      <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Terminal Address
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={""}
                                    label="Terminal Address"
                                    //onChange={handleChange}
                                  >
                                    <MenuItem value={10}>Pier 400</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                {/* <Item>
            <DesktopDatePicker
          label="Date desktop"
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        /></Item> */}
                              </Grid>
                              <Grid item xs={6}>
                                <Item>xs=8</Item>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box>
                            <Grid item xs={12}>
                              <Item>xs=12</Item>
                            </Grid>

                            <Grid item xs={12}>
                              <Item>
                                {" "}
                                <Button
                                  variant="text"
                                  className="update-button11"
                                >
                                  UPDATE
                                </Button>
                              </Item>
                            </Grid>
                          </Box>
                        </Typography>
                      </Box>
                    </Modal>

                    <Button
                      className="last-week-button middle-month-button"
                      variant="text"
                    >
                      Last week
                    </Button>
                    <Button
                      className="last-month-button last-month-button-w"
                      variant="text"
                    >
                      Last month
                    </Button>
                  </div>
                  <div className="appiontment-middle-contain">
                    {/* <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="standard-basic" label="Standard" variant="standard" />
      
    </Box> */}
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <Item className="top-appointment-con1 appoint-space1">
                            <h2>90%</h2>
                            <p>
                              {" "}
                              Total Adherence{" "}
                              <span className="green-button1">&nbsp;</span>
                            </p>
                          </Item>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Item className="top-appointment-con1 appoint-space1">
                            <h2>350</h2>
                            <p> Total Adherence</p>
                          </Item>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Item className="top-appointment-con1 appoint-space1">
                            <h2>Simplified Transport</h2>
                            <p>Top Carrier</p>
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>

                  <MissedAppointments/>

                  <div className="missed-appiontment missed-border1 b-space">
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container>
                        <Grid item xs={12} className="missed-heading">
                          <h2>Performance by Carrier</h2>
                        </Grid>
                        <Grid item xs={12}>
                          <TableContainer
                            className="main-table v2"
                            component={Paper}
                            style={{ maxHeight: "100vh" }}
                          >
                            <Table
                              stickyHeader
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Carrier</TableCell>
                                  <TableCell>Total</TableCell>
                                  <TableCell>Total Missed</TableCell>
                                  <TableCell>Adherence</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows.map((row) => (
                                  <TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell align="left">
                                      {row.id2}
                                    </TableCell>
                                    <TableCell>{row.id3}</TableCell>
                                    <TableCell align="left">
                                      <span className="adhurnce-button text-success">
                                        {row.id4}
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                ))}
                                {rows.map((row) => (
                                  <TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell align="left">
                                      {row.id2}
                                    </TableCell>
                                    <TableCell>{row.id3}</TableCell>
                                    <TableCell align="left">
                                      <span className="adhurnce-button text-success">
                                        {row.id4}
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </TabPanelUnstyled>
                <TabPanelUnstyled className="appointment-tab-top-con" value={1}>
                  <div className="appointments-tab-two">
                    <div className="dashboard-appointment-con dashboard-appointment-contab2">
                      <div className="mui-right-tab">
                        <Button
                          className="last-month-button custom-button"
                          variant="text"
                          onClick={handleOpen1}
                        >
                          custom date range{" "}
                          <CalendarTodayOutlinedIcon className="calendar-icon" />
                        </Button>

                        <Button
                          className="last-week-button middle-month-button"
                          variant="text"
                        >
                          Last week
                        </Button>
                        <Button
                          className="last-month-button last-month-button-w"
                          variant="text"
                        >
                          Last month
                        </Button>
                      </div>
                      <div className="mui-right-tab right-bg-t">
                        <div className="search-box">
                          {" "}
                          <Box
                            component="form"
                            sx={{
                              "& > :not(style)": { m: 1, width: "100%" },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              value={searchText}
                              onChange={handlesearchText}
                              id="outlined-adornment-password"
                              label="Search"
                              placeholder="Search"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    className="pe-2"
                                  >
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      edge="end"
                                      onClick={resetsearchText}
                                      style={{
                                        visibility:
                                          searchText?.length > 0
                                            ? "visible"
                                            : "hidden",
                                      }}
                                    >
                                      <ClearIcon />
                                    </IconButton>

                                    <IconButton
                                      aria-label="toggle password visibility"
                                      edge="end"
                                      onClick={resetsearchText}
                                    >
                                      <SearchOutlinedIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />

                            {/* <SearchOutlinedIcon className="search-icon" /> */}
                          </Box>
                        </div>{" "}
                        <FormControl className="t-width">
                          <InputLabel htmlFor="grouped-native-select">
                            Trucker
                          </InputLabel>
                          <Select
                            native
                            defaultValue=""
                            id="grouped-native-select"
                            label="Grouping"
                          >
                            <option aria-label="None" value="" />
                            <optgroup label="Category 1">
                              <option value={1}>Option 1</option>
                              <option value={2}>Option 2</option>
                            </optgroup>
                            <optgroup label="Category 2">
                              <option value={3}>Option 3</option>
                              <option value={4}>Option 4</option>
                            </optgroup>
                          </Select>
                        </FormControl>
                        <FormControl className="appoint-type" id="app-type-id">
                          <InputLabel htmlFor="grouped-native-select">
                            Appointment Type
                          </InputLabel>
                          <Select
                            native
                            defaultValue=""
                            id="grouped-native-select"
                            label="Appointment Type"
                          >
                            <option aria-label="None" value="" />
                            <optgroup label="Category 1">
                              <option value={1}>Option 1</option>
                              <option value={2}>Option 2</option>
                            </optgroup>
                            <optgroup label="Category 2">
                              <option value={3}>Option 3</option>
                              <option value={4}>Option 4</option>
                            </optgroup>
                          </Select>
                        </FormControl>
                        <FormControl className="shift-width">
                          <InputLabel htmlFor="grouped-select">
                            Shift
                          </InputLabel>
                          <Select
                            native
                            defaultValue=""
                            id="grouped-select"
                            label="Grouping"
                          >
                            <option aria-label="None" value="" />
                            <optgroup label="Category 1">
                              <option value={1}>Option 1</option>
                              <option value={2}>Option 2</option>
                            </optgroup>
                            <optgroup label="Category 2">
                              <option value={3}>Option 3</option>
                              <option value={4}>Option 4</option>
                            </optgroup>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="appointments-dashboard-table">
                      {/* <div
                        className="appoint-text11"
                        style={{ height: 400, width: "100%" }}
                      >
                        <DataGrid
                          rows={rows2}
                          columns={columns}
                          className={classes.dataGrid}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          checkboxSelection
                          disableExtendRowFullWidth={true}
                        />
                      </div> */}
                      <TableContainer
                        className="table-container"
                        component={Paper}
                        style={{ maxHeight: "100vh" }}
                      >
                        <Table
                          stickyHeader
                          aria-label="simple table"
                          className="appoint-ment-dashboard v2"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell className="c-head">
                                <Checkbox
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 28 },
                                  }}
                                />
                                Appointment #
                              </TableCell>
                              <TableCell className="c-head" align="center">
                                Appointment Type
                              </TableCell>
                              <TableCell className="c-head" align="center">
                                Date
                              </TableCell>
                              <TableCell className="c-head" align="center">
                                Slot
                              </TableCell>
                              <TableCell className="c-head" align="center">
                                Status
                              </TableCell>
                              <TableCell className="c-head" align="center">
                                Trucker
                              </TableCell>
                              <TableCell className="c-head" align="center">
                                Container
                              </TableCell>
                              <TableCell
                                className="c-head"
                                align="center"
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody sc>
                            {rows2.map((item, index) => {
                              return (
                                <TableRow key={item?.id} className="cursor">
                                  <TableCell component="th" scope="row">
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 28 },
                                      }}
                                    />
                                    {item?.Appointment}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {item?.type}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {item?.Date}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {item?.Slot}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button className="active-buttonRed ">
                                      <span className="redText">
                                        {item?.Status}
                                      </span>
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {item?.Trucker}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {item?.Container}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button>
                                      {" "}
                                      <h6>...</h6>
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Pagination
                        className="pagination-t"
                        count={10}
                        size="small"
                      />
                    </div>
                  </div>
                </TabPanelUnstyled>
              </TabsUnstyled>
            </div>
          </div>
        </div>
      </div>
      <NewModal open={open} onClose={handleClose} />
    </>
  );
}

const MissedAppointments = () => {
  return (
    <div className="missed-appiontment missed-border1 b-space">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} className="missed-heading">
            <h2>Missed Appointments</h2>
          </Grid>
          <Grid item xs={12}>
            <ResponsiveContainer width={"99%"} height={250}>
              <BarChart width={730} height={250} data={data}>
                {/* <CartesianGrid strokeDasharray="3 3"  /> */}
                <CartesianGrid
                  vertical={false}
                  strokeDasharray="0"
                  stroke="#ebf3f0"
                />
                <XAxis
                  axisLine={false}
                  style={{
                    fontSize: "12px",
                    letterSpacing: "0.4px",
                  }}
                  dy={15}
                  tick={{ fill: "#0b79d0" }}
                  dataKey="name"
                />
                {/* <YAxis /> */}
                {/* <Tooltip />
              <Legend /> */}

                <Bar barSize={20} dataKey="pv" fill="#303f9f" />
                <Bar barSize={20} dataKey="uv" fill="#e31b0c" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

const PerformanceByCarrier = () => {
  return <></>;
};

const NewModal = ({ open, onClose }) => {
  const [chipData, setChipData] = React.useState([
    { key: 0, label: "25632" },
    { key: 1, label: "85698" },
    { key: 2, label: "25632" },
  ]);

  const [select, setSelect] = useState("");

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  return (
    <Dialog className="new-exce" open={open} onClose={onClose}>
      <DialogTitle className="new-exception">
        New Exception
        <span className="cross-button" onClick={onClose}>
          <ClearOutlinedIcon />
        </span>
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
          To subscribe to this website, please enter your email address here. We
          will send updates occasionally.
        </DialogContentText> */}
        <div className="chef-com1">
          {chipData.map((data) => {
            return (
              <Chip
                label={data.label}
                onDelete={
                  data.label === "React" ? undefined : handleDelete(data)
                }
              />
            );
          })}

          {/* <Chip label="12365" onDelete={() => {}} />
          <Chip label="8569" onDelete={() => {}} /> */}
        </div>
        <div className="chef-com12">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Reason</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={select}
                label="Reason"
                onChange={(e) => setSelect(e.target.value)}
                //onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="chef-com1">
          <Button variant="text" className="grant-exception">
            grant exception
          </Button>
        </div>
        {/* <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
        /> */}
      </DialogContent>
    </Dialog>
  );
};
