import moment from "moment";
let _ = require("lodash");

export function validEmail(elementValue) {
  let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(elementValue);
}

export function validPhone(elementValue) {

//regex check for digits, spaces, brackets, dashes
  let phonePattern = /^[- +()]*[0-9][- +()0-9]*$/;
  return phonePattern.test(elementValue);
  
}

export function validPassword(str) {
  let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}
export function checkAmnt(str) {
  let re = /^[0-9]*$/;
  return re.test(str);
}

export function checkName(str) {
  let re = /^[a-zA-Z ]+$/;

  return re.test(str);
}

export function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export function getLinesCount(element) {
  let prevLH = element.style.lineHeight;
  let factor = 1000;
  element.style.lineHeight = factor + "px";

  let height = element.getBoundingClientRect().height;
  element.style.lineHeight = prevLH;

  return Math.floor(height / factor);
}

export const GMT_to_normal_time = (time) => {
  // let temp = time.split("-08:00")[0];
  return moment(time).utc().format("HH:mm");
};

export const Time_diff = (start, end) => {
  let startTime = moment(GMT_to_normal_time(start), "HH:mm:ss");
  let endTime = moment(GMT_to_normal_time(end), "HH:mm:ss");

  // calculate total duration
  let duration = moment.duration(endTime?.diff(startTime));
  return duration;
};

export const filter_by_progress = (data, sort_type) => {
  let temp = [];
  data?.map((item) => {
    let progress = Math.ceil(
      ((item?.containercount - item?.container_used) / item?.containercount) *
        100
    );

    temp.push({
      ...item,
      progress,
    });
  });
  temp = _.orderBy(temp, "progress", sort_type);

  return temp;
};

export const getTimeLaps = (start, end) => {
  let startdate_Format = moment(start.split("-08:00")[0]).format(
    "DD-MM-YYYY HH.mm"
  );
  let enddate_Format = moment(end.split("-08:00")[0]).format(
    "DD-MM-YYYY HH.mm"
  );

  let end_date = moment(enddate_Format, "DD-MM-YYYY HH.mm");
  let start_date = moment(startdate_Format, "DD-MM-YYYY HH.mm");

  let duration = moment.duration(end_date.diff(start_date));

  let hours = parseInt(duration.asHours());
  let minutes = parseInt(duration.asMinutes()) % 60;
  let seconds = parseInt(duration.asSeconds()) % 60;
  let x = {
    hours,
    minutes,
    seconds,
  };

  return x;
};

export const getTimeLaps2 = (time) => {
  var duration = moment.duration(time, "minutes");
  let hours = parseInt(duration.asHours());
  let minutes = parseInt(duration.asMinutes()) % 60;
  let seconds = parseInt(duration.asSeconds()) % 60;


  return `${parseInt(hours)}H ${parseInt(minutes)} MINS`;
};

export const getDuration = (time) => {
  var duration = moment.duration(time, "seconds");
  let hours = duration.asHours();
  let minutes = parseInt(duration.asMinutes()) % 60;
  let seconds = parseInt(duration.asSeconds()) % 60;

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${parseFloat(hours).toFixed(0)}  Hours`;
};


export const getDuration2 = (time) => {
  var duration = moment.duration(time, "seconds");
  let hours = parseInt(duration.asHours());
  let minutes = parseInt(duration.asMinutes()) % 60;
  let seconds = parseInt(duration.asSeconds()) % 60;

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (hours < 10) {
    
    hours = `0${hours}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${hours}:${minutes}:${seconds}`;
};



export const get_diff = (start, end) => {
  let startSplit = start !== undefined && start.split("T")[0];
  let endSplit = end !== undefined && end.split("T")[0];
  let startShift = `${startSplit}T07:00:00.000+05:30`;
  let endShift = `${endSplit}T03:00:00.000+05:30`;
  if (moment(endShift).format("d") === 6) {
    endShift = `${endSplit}T17:00:00.000+05:30`;
  }

  let startdate_Format = moment(startShift).format("DD-MM-YYYY HH.mm");
  let enddate_Format = moment(endShift).format("DD-MM-YYYY HH.mm");
  let end_date = moment(enddate_Format, "DD-MM-YYYY HH.mm");
  let start_date = moment(startdate_Format, "DD-MM-YYYY HH.mm");

  return end_date.diff(start_date, "hours");
};

export const time_remaining = (start, end) => {
  let x = {};
  let startSplit = start !== undefined && start.split("T")[0];
  let endSplit = end !== undefined && end.split("T")[0];
  let startShift = `${startSplit}T07:00:00.000+05:30`;
  let endShift = `${endSplit}T03:12:20.000+05:30`;
  if (moment(endShift).format("d") === 6) {
    endShift = `${endSplit}T17:00:00.000+05:30`;
  }
  let currentDate = new Date();
  let currentDateFormat = moment(currentDate).toISOString();
  let startdate_Format = moment(currentDateFormat).format(
    "DD-MM-YYYY HH.mm.ss"
  );
  let enddate_Format = moment(endShift).format("DD-MM-YYYY HH.mm.ss");
  let end_date = moment(enddate_Format, "DD-MM-YYYY HH.mm.ss");
  let start_date = moment(startdate_Format, "DD-MM-YYYY HH.mm.ss");
  let duration = moment.duration(end_date.diff(start_date));
  let hours = parseInt(duration.asHours());
  let minutes = parseInt(duration.asMinutes()) % 60;
  let seconds = parseInt(duration.asSeconds()) % 60;
  x = {
    hours,
    minutes,
    seconds,
  };
  return x;
};

export const showTimeRemaining = (time) => {
  let duration = moment.duration(time, "s");
  let hours = parseInt(duration.asHours());
  let minutes = parseInt(duration.asMinutes()) % 60;
  let seconds = parseInt(duration.asSeconds()) % 60;

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  let x = {
    hours,
    minutes,
    seconds,
  };

  return x;
};

export const calculateTimeRemaining = () => {
  let time = localStorage.getItem("time") ? localStorage.getItem("time") : "0";
  let duration = moment.duration(time, "s");
  let hours = parseInt(duration.asHours());
  let minutes = parseInt(duration.asMinutes()) % 60;
  let seconds = parseInt(duration.asSeconds()) % 60;

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  let x = {
    hours,
    minutes,
    seconds,
  };
  let z = time - 2;
  localStorage.setItem("time", z);
  return x;
};

export const number_of_shifts = (start, end) => {
  let startSplit = start !== undefined && start.split("T")[0];
  let endSplit = end !== undefined && end.split("T")[0];
  let startShift = `${startSplit}T07:00:00.000+05:30`;
  let endShift = `${endSplit}T03:00:00.000+05:30`;
  if (moment(endShift).format("d") === 6) {
    endShift = `${endSplit}T17:00:00.000+05:30`;
  }

  let startdate_Format = moment(startShift).format("DD-MM-YYYY HH.mm");
  let enddate_Format = moment(endShift).format("DD-MM-YYYY HH.mm");
  let end_date = moment(enddate_Format, "DD-MM-YYYY HH.mm");
  let start_date = moment(startdate_Format, "DD-MM-YYYY HH.mm");
  let diff = end_date.diff(start_date, "days");

  let dates = [];
  let numberOfShift = 0;

  while (moment(startShift).valueOf() <= moment(endShift).valueOf()) {
    if (moment(startShift).format("d") === "6") {
      numberOfShift += 1;
    } else if (moment(startShift).format("d") === "0") {
      numberOfShift += 0;
    } else {
      numberOfShift += 2;
    }

    startShift = moment(startShift).add(1, "days");
  }

  return numberOfShift;
};

export const getCurrentShift = (value, start, end) => {
  let currentShift = 0;
  let startSplit = start !== undefined && start.split("T")[0];
  let endSplit = end !== undefined && end.split("T")[0];
  let startShift = `${startSplit}T07:00:00.000+05:30`;
  let endShift = `${endSplit}T03:00:00.000+05:30`;
  if (moment(endShift).format("d") === 6) {
    endShift = `${endSplit}T17:00:00.000+05:30`;
  }

  let date = new Date();

  let todaydate = moment(date).utc().toISOString();

  if (moment(todaydate).valueOf() < moment(startShift).valueOf()) {
    return null;
  }

 

  //if value%2===o -> means first shift
  if (value % 2 === 0) {
    //compare today start date with start date
  }
  //else second shift
  else {
  }

  return currentShift;
};

export const Shift_Time_remaining = (start, end) => {
  let x;

  let date = new Date();
  let today = moment(date).utc();

  if (moment(today) >= moment(start)) {
    let diff = moment(end).diff(today, "seconds");

    if (diff > 0) {
      let duration = moment.duration(diff, "s");
      let hours = parseInt(duration.asHours());
      let minutes = parseInt(duration.asMinutes()) % 60;
      let seconds = parseInt(duration.asSeconds()) % 60;

      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (hours < 10) {
        hours = `0${hours}`;
      }
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      x = {
        hours,
        minutes,
        seconds,
      };
      return x;
    }
  }

  x = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  

  return x;
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};
