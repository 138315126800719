import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import "./Testimonial.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Header from "../Sharemodule/Header/Header";
import Button from "@mui/material/Button";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  Slider,
  Chip,
  Backdrop,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { StyledModal } from "../Home/Home";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addTerminalList, editTerminalList, fetchTerminalList } from "../../Redux/TerminalSlice";
import NoContentCard from "../NoContent/NoContentCard";
import TableSkeleton from "../Skeleton/TableSkeleton";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Pier 400", "Los Angeles, CA", 90, 26),
  createData("Port Elizabeth", "Los Angeles, CA", 25, 20),
  createData("Pier 400", "Los Angeles, CA", 90, 26),
  createData("Port Elizabeth", "Los Angeles, CA", 25, 20),
  createData("Pier 400", "Los Angeles, CA", 90, 26),
  createData("Port Elizabeth", "Los Angeles, CA", 25, 20),
  createData("Pier 400", "Los Angeles, CA", 90, 26),
  createData("Port Elizabeth", "Los Angeles, CA", 25, 20),
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const marks = [
  {
    value: 0.5,
    label: "0.5",
  },
  {
    value: 2.5,
    label: " 2.5",
  },
  {
    value: 4.5,
    label: "4.5",
  },
  {
    value: 6.5,
    label: "6.5",
  },
  {
    value: 8.5,
    label: "8.5",
  },
  {
    value: 10.5,
    label: "10.5",
  },
  {
    value: 12,
    label: "12",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Terminals() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { terminalList, terminalListStatus } = useSelector(
    (state) => state.terminal
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchTerminalList());
  }, []);
  return (
    <>
      <Header />
      <div className="main-container">
        <div className="brade-camp brade-camp2 dashboard-top2">
          <>
            <Link to="/">Home</Link>
            <span>
              /&nbsp;
              <Link style={{ color: "black" }} to="/">
                Terminals
              </Link>
            </span>
          </>
        </div>
        <div class="dashboard-contain">
          <div class="dashboard-top">
            <h1>Terminals</h1>
            <Button
              variant="text"
              className="schedule-button"
              onClick={handleOpen}
            >
              Add Terminal
            </Button>
            <AddModal open={open} onClose={handleClose} />
          </div>
          <TableContainer
            style={{ maxHeight: "100vh" }}
            className="main-table v2"
            component={Paper}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Terminal</TableCell>
                  <TableCell align="right">Location</TableCell>
                  <TableCell align="right">Adherence</TableCell>
                  <TableCell align="right">Motor Carriers</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {terminalListStatus === "loading" || terminalList === null ? (
                  <>
                    {Array.from({ length: 10 }, (_, i) => (
                      <TableSkeleton key={i} count={4} />
                    ))}
                  </>
                ) : (
                  <>
                    {terminalList?.length > 0 && (
                      <>
                        {terminalList?.map((row) => {
                          return <TableRows key={row?._id} row={row} />;
                        })}
                      </>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {terminalList?.length === 0 && <NoContentCard msg="No data found" />}
        </div>
      </div>
    </>
  );
}

const TableRows = ({ row }) => {
  const history = useHistory();
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const goto = () => {
    history.push(`/appointments/${row?._id}`);
  };

  return (
    <TableRow
      className="cursor"
      key={row.name}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell onClick={goto} component="th" scope="row">
        {row?.terminal_name}
      </TableCell>
      <TableCell onClick={goto} align="right">
        {row?.terminal_address}
      </TableCell>
      <TableCell onClick={goto} align="right">
        N/A
        {/* <span className="adhurnce-button text-success">{row?.fat} %</span> */}
      </TableCell>
      <TableCell onClick={goto} align="right">
        {/* {row.carbs} */}
        N/A
      </TableCell>
      {/* <TableCell onClick={goto} align="right">
        {row.protein}
      </TableCell> */}
      <TableCell align="right">
        <Button variant="text" onClick={handleOpen1}>
          <h6>...</h6>
        </Button>
        <NewModal open={open1} data={row} onClose={handleClose1} />
      </TableCell>
    </TableRow>
  );
};

const AddModal = ({ open, onClose }) => {
  const [program_start_date, set_program_start_date] = useState(new Date());
  const [tolarenceLevel, set_tolarenceLevel] = useState(0);
  const [programName, set_programName] = useState("");
  const [address, set_address] = useState("");
  const [ForgivenessDays, setForgivenessDays] = useState([]);
  const [err_tolarenceLevel, set_tolarenceLevel_err] = useState(null);
  const [err_ForgivenessDays, set_ForgivenessDays_err] = useState(null);
  const [err_programName, set_programName_err] = useState(null);
  const [err_address, set_address_err] = useState(null);
  const dispatch = useDispatch();
  const { add_terminalListStatus,add_terminal_added  } = useSelector(
    (state) => state.terminal
  );

  const handleForgivenessDays = (value) => {
    set_program_start_date(value);
    setForgivenessDays([...ForgivenessDays, moment(value).toISOString()]);
  };

  function valuetext(value) {
    return `${value} hours`;
  }

  const resetAll=()=>{
    set_program_start_date(new Date());
    set_tolarenceLevel(0);
    set_programName("");
    set_address("");
    setForgivenessDays([]);
    set_tolarenceLevel_err(null);
    set_ForgivenessDays_err(null);
    set_programName_err(null);
    set_address_err(null);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    set_tolarenceLevel_err(null);
    set_ForgivenessDays_err(null);
    set_programName_err(null);
    set_address_err(null);

    //form validation
    if (programName === "") {
      set_programName_err("Please enter program name");
      return;
    }
    if (address === "") {
      set_address_err("Please enter address");
      return;
    }
    if (tolarenceLevel === 0) {
      set_tolarenceLevel_err("Please select tolarence level");
      return;
    }
    // if (ForgivenessDays.length < 2) {
    //   set_ForgivenessDays_err("Please select atleast two days");
    //   return;
    // }
    //form validation
    let _data = {
      missed_appointment_tolerance_time: tolarenceLevel,
      forgiveness_date: ForgivenessDays,
      terminal_address: address,
      terminal_name: programName,
    };


    dispatch(addTerminalList(_data));

    //submit
    //close modal
  };

  useEffect(() => {
    if (add_terminal_added) {
      onClose();
      resetAll();
      dispatch(fetchTerminalList());
    }
  }, [add_terminal_added]);

  useEffect(()=>{
    resetAll()
  },[open])

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id="kk"
      className="Terminal-Configuration-m"
      BackdropComponent={Backdrop}
    >
      {/* <Box className="m-t " sx={style}> */}
      {/* <Typography id="modal-modal-title" variant="h6" component="h2" className="Terminal-Configuration">
      id="kk"
      BackdropComponent={Backdrop}
    > */}

      <Box className="m-t terminals-modern" sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="d-flex justify-content-between heading-modal p-2"
        >
          Terminal Configuration 
          <span className="close-modal cursor" onClick={onClose}>
            <ClearOutlinedIcon />
          </span>
        </Typography>
        <form
          component="form"
          onSubmit={handleSubmit}
          id="modal-modal-description"
          sx={{ mt: 2 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className="tg-con14">
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">
                    Terminal name
                  </InputLabel> */}
                  <TextField
                    label=" Terminal name"
                    placeholder="Terminal name"
                    value={programName}
                    onChange={(e) => set_programName(e.target.value)}
                  />
                  <label className="errText">{err_programName}</label>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className="tg-con14">
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                    Terminal Address
                  </InputLabel> */}
                <TextField
                  label=" Terminal address"
                  placeholder="Terminal address"
                  value={address}
                  onChange={(e) => set_address(e.target.value)}
                />
                <label className="errText">{err_address}</label>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} className="tg-con">
              <FormControl fullWidth className="p-e-date">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Forgiveness days"
                    value={program_start_date}
                    onChange={(newValue) => {
                      handleForgivenessDays(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
              <Item className="chef-component">
                {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      listStyle: "none",
                      p: 0.5,
                      m: 0,
                    }}
                  > */}

                {ForgivenessDays?.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      onDelete={() => {
                        setForgivenessDays(
                          ForgivenessDays.filter((i) => i !== item)
                        );
                      }}
                      label={moment(item).format("MM-DD-YYYY")}
                      className="chef-com"
                    />
                  );
                })}
              </Item>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography id="modal-modal-title" className="tg-label">
                Missed Appointments Tolerance {`(${tolarenceLevel} hours)`}
              </Typography>
              <Item>
                {" "}
                <Slider
                  aria-label="Custom marks"
                  value={tolarenceLevel}
                  onChange={(event, newValue) => {
                    set_tolarenceLevel(newValue);
                  }}
                  getAriaValueText={valuetext}
                  valueLabelFormat={ValueLabelComponent}
                  marks={marks}
                  valueLabelDisplay="auto"
                  min={0}
                  step={0.5}
                  max={12}
                />
              </Item>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Item>
              {" "}
              <Button
                disabled={add_terminalListStatus === "loading"}
                type="submit"
                variant="text"
                className="update-button11"
              >
                {add_terminalListStatus === "loading" && (
                  <CircularProgress color="inherit" size={15} />
                )}{" "}
                Add 
              </Button>
            </Item>
          </Grid>
        </form>
        {/* </Box> */}
      </Box>
    </StyledModal>
  );
};

const NewModal = ({ open, onClose, data }) => {
  const [program_start_date, set_program_start_date] = useState(new Date());
  const [tolarenceLevel, set_tolarenceLevel] = useState(
    data?.missed_appointment_tolerance_time
  );
  const [programName, set_programName] = useState(data?.terminal_name);
  const [address, set_address] = useState(data?.terminal_address);
  const [ForgivenessDays, setForgivenessDays] = useState(
    data?.forgiveness_date
  );
  const [err_tolarenceLevel, set_tolarenceLevel_err] = useState(null);
  const [err_ForgivenessDays, set_ForgivenessDays_err] = useState(null);
  const [err_programName, set_programName_err] = useState(null);
  const [err_address, set_address_err] = useState(null);
  const dispatch = useDispatch();
  const { edit_terminal_edited, edit_terminalListStatus } = useSelector(
    (state) => state.terminal
  );

  const handleForgivenessDays = (value) => {
    set_program_start_date(value);
    setForgivenessDays([...ForgivenessDays, moment(value).toISOString()]);
  };

  function valuetext(value) {
    return `${value} hours`;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    set_tolarenceLevel_err(null);
    set_ForgivenessDays_err(null);
    set_programName_err(null);
    set_address_err(null);

    //form validation
    if (programName === "") {
      set_programName_err("Please enter program name");
      return;
    }
    if (address === "") {
      set_address_err("Please enter address");
      return;
    }
    if (tolarenceLevel === 0) {
      set_tolarenceLevel_err("Please select tolarence level");
      return;
    }
    // if (ForgivenessDays.length < 2) {
    //   set_ForgivenessDays_err("Please select atleast two days");
    //   return;
    // }
    //form validation
    let _data = {
      missed_appointment_tolerance_time: tolarenceLevel,
      forgiveness_date: ForgivenessDays,
      terminal_address: address,
      terminal_name: programName,
    };

    let payload = {
      _data,
      id: data?._id,
    };

    dispatch(editTerminalList(payload));

    //submit
    //close modal
  };

  useEffect(() => {
    if (edit_terminal_edited) {
      onClose();
      dispatch(fetchTerminalList());
    }
  }, [edit_terminal_edited]);

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id="kk"
      className="Terminal-Configuration-m"
      BackdropComponent={Backdrop}
    >
      {/* <Box className="m-t " sx={style}> */}
      {/* <Typography id="modal-modal-title" variant="h6" component="h2" className="Terminal-Configuration">
      id="kk"
      BackdropComponent={Backdrop}
    > */}

      <Box className="m-t terminals-modern" sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="d-flex justify-content-between heading-modal p-2"
        >
          Terminal Configuration - {data?.terminal_name}
          <span className="close-modal cursor" onClick={onClose}>
            <ClearOutlinedIcon />
          </span>
        </Typography>
        <form
          component="form"
          onSubmit={handleSubmit}
          id="modal-modal-description"
          sx={{ mt: 2 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className="tg-con14">
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">
                    Terminal name
                  </InputLabel> */}
                  <TextField
                    label=" Terminal name"
                    placeholder="Terminal name"
                    value={programName}
                    onChange={(e) => set_programName(e.target.value)}
                  />
                  <label className="errText">{err_programName}</label>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className="tg-con14">
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                    Terminal Address
                  </InputLabel> */}
                <TextField
                  label=" Terminal address"
                  placeholder="Terminal address"
                  value={address}
                  onChange={(e) => set_address(e.target.value)}
                />
                <label className="errText">{err_address}</label>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} className="tg-con">
              <FormControl fullWidth className="p-e-date">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Forgiveness days"
                    value={program_start_date}
                    onChange={(newValue) => {
                      handleForgivenessDays(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
              <Item className="chef-component">
                {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      listStyle: "none",
                      p: 0.5,
                      m: 0,
                    }}
                  > */}

                {ForgivenessDays?.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      onDelete={() => {
                        setForgivenessDays(
                          ForgivenessDays.filter((i) => i !== item)
                        );
                      }}
                      label={moment(item).format("MM-DD-YYYY")}
                      className="chef-com"
                    />
                  );
                })}
              </Item>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography id="modal-modal-title" className="tg-label">
                Missed Appointments Tolerance {`(${tolarenceLevel} hours)`}
              </Typography>
              <Item>
                {" "}
                <Slider
                  aria-label="Custom marks"
                  value={tolarenceLevel}
                  onChange={(event, newValue) => {
                    set_tolarenceLevel(newValue);
                  }}
                  getAriaValueText={valuetext}
                  valueLabelFormat={ValueLabelComponent}
                  marks={marks}
                  valueLabelDisplay="auto"
                  min={0}
                  step={0.5}
                  max={12}
                />
              </Item>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Item>
              {" "}
              <Button
                disabled={edit_terminalListStatus === "loading"}
                type="submit"
                variant="text"
                className="update-button11"
              >
                {edit_terminalListStatus === "loading" && (
                  <CircularProgress color="inherit" size={15} />
                )}{" "}
                UPDATE
              </Button>
            </Item>
          </Grid>
        </form>
        {/* </Box> */}
      </Box>
    </StyledModal>
  );
};

const ValueLabelComponent = (props) => {
  const { children, open, value } = props;
  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="bottom"
      title={() => <span className="bg-dark">`${value}%`</span>}
      className="text-white"
    >
      <div style={{ marginBottom: "20px" }}>{props} Hours</div>
    </Tooltip>
  );
};
