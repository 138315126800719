import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

const TableSkeleton = ({ count }) => {
  return (
    <TableRow>
      <TableCell component="td" scope="row">
        <Skeleton animation="wave" />
      </TableCell>

      {Array(count)
        .fill(0)
        .map((_, index) => (
          <TableCell key={index} align="left">
            <Skeleton animation="wave" />
          </TableCell>
        ))}
    </TableRow>
  );
};

export default TableSkeleton;
